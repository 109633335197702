//================================================================
//  Component: Upload File Modal
//================================================================

//  Purpose: This is the upload file modal for chat feature

//  Properties:
//    - open = {useState to open file modal}
//    - setOpen = {useState to change from open to closed}
//    - uploadPDFCSV = {function for uploading files - from Chat.js}
//    - formData = {useReducer for setting status of file upload}
//    - setFormData = {useReducer for changing status of file upload}

//  <UploadFileModal
//    open={openFileModal}
//    setOpen={setOpenFileModal}
//    uploadPDFCSV={uploadPDFCSV}
//    formData={formData}
//    setFormData={setFormData}
//  ></UploadFileModal>   

//================================================================


//Libraries
import React from 'react';

//Images
import uploadError from '../../../Components/Images/PDF_Error.svg';
import pdfCSVUploadOnload from '../../../Components/Images/Icon_PDF_CSV_Upload_Onload.svg';
import closeButton from '../../../Components/Images/Close-Button.svg'
import loadingDots from '../../../Components/Images/Loading-Dots.gif'

//CSS
import './UploadFileModal.css';


export default function UploadFileModal({
  open,
  setOpen,
  uploadPDFCSV,
  formData,
  setFormData
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Keeps modal closed while not in use
  if (!open) return null;

  // Onload state when upload file icon button is clicked on chat screen
  if (formData.uploadStatus === 'onload') {
    return (
      <div className='Modal-Overlay' onClick={() => setOpen(false)}>

        <dialog className='Modal-Container' onClick={(e) => { e.stopPropagation() }}>

          {/* Close Button */}
          <div className='Modal-Title'>
            <h5>Upload a file</h5>
            <button
              onClick={() => setOpen(false)}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}
              >
              <img src={closeButton} alt={''}></img>
            </button>
          </div>

          {/* Drop File */}
          <div className='Modal-Drag-Container'
            onDragOver={(e) => {

              e.preventDefault();

            }}
            onDrop={(e) => {

              e.preventDefault();
              uploadPDFCSV(e.dataTransfer.files[0]);

            }}
          >
            <img src={pdfCSVUploadOnload} alt='icon-upload-pdf'></img>
          </div>
          <b className='Modal-OR'>OR</b>

          {/* Choose file button */}
          <div className='Modal-Choose-File-Button'>
            <input
              className='Modal-Choose-File-Button'
              type='file'
              accept='.pdf, .csv'
              onChange={(e) => {

                uploadPDFCSV(e.target.files[0]);

              }}
            ></input>

          </div>

        </dialog>

      </div>
    )
  }

  // Modal for pending status - when a file takes a long time to upload - also closes modal when upload successful
  else if (formData.uploadStatus === 'pending') {
    return (
      <div className='Modal-Overlay'>
        <dialog className='Modal-Container'>

          {/* Close Button */}
          <div className='Modal-Title'>
            <h5>Upload a file</h5>
            <button
              onClick={() => setOpen(false)}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}>
              <img src={closeButton} alt={''}></img>
            </button>
          </div>

          {/* Loading animation */}
          <div className='Modal-Load-Container'>
            <img className='Modal-File-Loading' src={loadingDots} alt={'file loading'}></img>
          </div>
          <b className='Modal-OR'>
            File uploading...
          </b>
        </dialog>
      </div>
    )
  }

  // Any error in uploading file - {formData.fileError displays errors set in UploadPDFCSV function in chat.js}
  else {
    return (
      <div className='Modal-Overlay'>
        <dialog className='Modal-Container'>

          {/* Close Button */}
          <div className='Modal-Title'>
            <h5>Upload a file</h5>
            <button
              onClick={() => setOpen(false)}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}>
              <img 
              className='Modal-Close-Button'
              src={closeButton} 
              alt={'Close Modal'}></img>
            </button>
          </div>

          {/* Something went wrong error image */}
          <div className='Modal-Drag-Container'>
            <img
              alt='pdf-upload-icon'
              src={uploadError}
            ></img>
          </div>
          <b className='Modal-OR'>
            {formData.fileError}
          </b>

          {/* Select another file button - takes user back on onload/upload file modal */}
          <button className='Modal-Select-Another-File-Button' onClick={() => (setFormData(formData.uploadStatus = 'onload'))} >Select another file</button>
        </dialog>
      </div>
    )
  }
}
