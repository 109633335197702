//================================================================
//  Chat Settings
//================================================================

//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import ContextForm from './Components/ContextForm';
import Temperature from './Components/Temperature';

//Functions

//Images
import iconClose from '../../Components/Images/Close_Icon_Blue.svg';

//CSS
import './ChatSettings.css';


export default function ChatSettings() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={['isUser', 'isAdmin']}
        requiredRolesValue={true}
        status={'onload'}
        body={
          <>

            {/* ====================================== */}
            {/*               Nav                      */}
            {/* ====================================== */}

            <div className='ChatSettings-Header'>
              <h2>Chat Settings</h2>
              <img className='Chat-Settings-Icon' src={iconClose} alt='icon-close' onClick={() => navigate('/chat')}></img>
            </div>

            <div className='ChatSettings-Body'>

              {/* ====================================== */}
              {/*               Context                  */}
              {/* ====================================== */}

              <h3>Context</h3>
              <div style={{lineHeight: '1.75'}}>
                Use context in a chat prompt to customize the behavior of the chat model. For example, you can use context to tell a model how to respond or give the model reference information to use when generating response. You might use context to do the following:
                <br></br>
                <ul>
                  <li>Specify words that the model can and can't use.</li>
                  <li>Specify topics to focus on or avoid.</li>  
                  <li>Specify the style, tone, or format of the response.</li>  
                  <li>Assume a character, figure, or role.</li>
                  <li>
                    <a 
                      href='https://cloud.google.com/vertex-ai/docs/generative-ai/chat/test-chat-prompts#test_chat_prompts'
                      target='_blank'
                      rel='noreferrer'>
                      Context best practices
                    </a>
                  </li>
                </ul>
              </div>
              <ContextForm
                collectionId={'context'}
                documentId={`context`}
              ></ContextForm>

              {/* ====================================== */}
              {/*               Tuning                   */}
              {/* ====================================== */}

              <h4 style={{paddingTop:'30px'}}>Tuning (optional)</h4>
              <p style={{lineHeight: '1.75'}}>
                Designed for advanced users, these controls allow the finetuning of responses, refer to the <a 
                  href='https://cloud.google.com/vertex-ai/docs/generative-ai/chat/test-chat-prompts#example_chat_prompt'
                  target='_blank'
                  rel='noreferrer'>
                  Google documentation
                </a> for more information.
              </p>

              <div style={{padding: '1%'}}>

                {/* Temperature */}
                <div style={{lineHeight: '1.75'}}>
                  <strong>Temperature</strong> controls the degree of randomness or creative flair in the AI's responses.  
                  <br></br>
                  <ul>
                    <li>A temperature of 0% is deterministic, meaning that the highest probability response is always selected.</li>
                    <li>A temperature of 100% is nondeterministic, meaning that the lowest probability response is always selected.</li> 
                    <li>For most use cases, try starting with a temperature of <strong>20%</strong>. If the model returns a response that's too generic, too short, or the model gives a fallback response, try increasing the temperature.</li>  
                  </ul>
                  <Temperature
                    collectionId={'context'}
                  ></Temperature>
                </div>

              </div>

            </div>


          </>
        }
      ></PageComponent>
    )
}