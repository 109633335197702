//================================================================
//  Image page
//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Components
import CurrentThread from './ImageThread';
import IconAnimation from '../../../Components/IconAnimation/IconAnimation';

//Functions
import writeDocument from '../../../Library/WriteDocument';
import updateDocument from '../../../Library/UpdateDocument';

//Images
import searchIcon from '../../../Components/Images/Icon_Search_Black.svg';
import deleteIcon from '../../../Components/Images/Icon_Delete_Blue.svg';
import deleteAnimation from '../../../Components/Images/Delete-bin.gif';
import backIcon from '../../../Components/Images/Icon_Back_Blue.svg';
import cloudFile from '../../../Components/Images/Cloud-File.svg';
import uploadFileAnimation from '../../../Components/Images/Upload-file-cloud.gif';

//CSS
import '../Image.css';


export default function Edit({
  formData,
  setFormData,
  editThread,
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Exit image prompt handler
    function editImageHandler(e) {
  
      // Conditions
      if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e !== 'ManualClick') return;

      // Validate input
      if (formData.prompt.length === 0) {

        return setFormData({ 'imageError': 'Ummmm, are you going to ask me something?' });
      }

      // Check for uploaded file input
      if (formData.base64string.length === 0) {
        
        const documentId = `${getUser.emailaddress}-${Date.now()}`;
        let document = {
          'id': documentId,
          'emailaddress': getUser.emailaddress,
          'prompt': formData.prompt,
          'answer': 'Ummmm, are you going to upload an image?',
          'status': 'complete-text',
          'requesttype': 'edit',
          'base64string': '',
          'hidden': false,
        };
  
        return writeDocument('images', documentId, document, false).then(() => {
  
          formData.prompt = '';
          setFormData(formData);
  
        }).catch((error) => {
  
          formData.prompt = '';
          formData.imageError = 'Unknown error occured, try again later.'
          setFormData(formData);
          setAppErrors(`Failed to submit prompt for user, error: ${error}`);
  
        });

      }

      // Reset input
      setFormData({'imageError': ''});

      // Write question to firestore
      const documentId = `${getUser.emailaddress}-${Date.now()}`;
      let document = {
        'id': documentId,
        'emailaddress': getUser.emailaddress,
        'prompt': formData.prompt,
        'status': 'pending',
        'requesttype': 'edit',
        'base64string': formData.base64string,
        'hidden': false,
      };

      writeDocument('images', documentId, document, false).then(() => {

        formData.prompt = '';
        setFormData(formData);

      }).catch((error) => {

        formData.prompt = '';
        formData.imageError = 'Unknown error occured, try again later.'
        setFormData(formData);
        setAppErrors(`Failed to submit prompt for user, error: ${error}`);

      });

    };

    // Delete the current thread
    function deleteImageHistory(){

      // Hide all editThread
      const chatPromises = [];
      editThread.forEach((document) => {

        if (document.id === undefined) return;

        chatPromises.push(
          updateDocument('images', document.id, {
            'hidden': true,
          })
        );

      });

      // Reset the thread
      setFormData({'base64string': ''});

      Promise.all(chatPromises).catch((error) => {

        setFormData({
          'promptError': 'Unknown error occured while removing history, try again later.'
        });
        setAppErrors(`Failed to delete prompt for user, error: ${error}`);

      });

    };
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Hide this input if the mode isn't 'edit'
    if (formData.mode !== 'edit') return null;

    return (

      <>
        <CurrentThread
          currentThread={editThread}
        ></CurrentThread>

        <div className='Image-Input-Container'>
    
          {/* Image input box */}
          <input
            className='Image-Search'
            placeholder={formData.imageError}
            value={formData.prompt}
            onChange={(e) => setFormData({ 'prompt': e.target.value })}
            onKeyDown={(e) => editImageHandler(e)}
          ></input>

          {/* Search, upload file, delete, and settings thread icon */}
          <div className='Image-Icons'>
            <img
              alt='search-icon'
              src={searchIcon}
              onClick={() => editImageHandler('ManualClick')}
              title='Submit'
            ></img>
            <IconAnimation
                className={'Text-Delete-Icon' }
                tooltip={'Delete Thread'}
                onClickHandler={() => deleteImageHistory()}
                iconDefault={deleteIcon}
                iconAnimation={deleteAnimation}
            ></IconAnimation> 
            <IconAnimation
                className={'Chat-UploadFile-Icon'}
                tooltip={'Upload a File'}
                onClickHandler={() => setFormData({'openImageModal': true})}
                iconDefault={cloudFile}
                iconAnimation={uploadFileAnimation}
            ></IconAnimation>   
            <img
              alt='back-icon'
              src={backIcon}
              onClick={() => setFormData({'mode': 'generate'})}
              title='Go back to image generation'
            ></img>
 
          </div>

        </div>
      </>
    )
}
