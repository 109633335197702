//================================================================
//  Text page
//================================================================

//Libraries
import React from 'react';

//Contexts

//Components
import IconAnimation from '../../../Components/IconAnimation/IconAnimation';

//Functions

//Images
import searchIcon from '../../../Components/Images/Icon_Search_Black.svg';
import chatloadingIcon from '../../../Components/Images/Icon_Chat_Loading.gif';
import deleteIcon from '../../../Components/Images/Icon_Delete_Blue.svg';
import deleteAnimation from '../../../Components/Images/Delete-bin.gif';
import cloudFile from '../../../Components/Images/Cloud-File.svg';
import uploadFileAnimation from '../../../Components/Images/Upload-file-cloud.gif';
import toolTip from '../../../Components/Images/Icon_LightBlub_Blue.svg';

//CSS
import '../Text.css';


export default function Extraction({
    formData,
    setFormData,
    textHandler,
    deleteTextHistory,
    setOpenFileModal,
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Hide this input if the mode isn't 'Extraction'
    if (formData.mode !== 'Extraction') return null;

    // Show the input
    return (
        <div className='Text-Input-Container'>

            {/* Free form input box for users */}
            <div className='Text-Prompt-Container'>

                <label>
                    Prompt:
                    {/* Tool Tip */}
                    <img 
                        style={{width: '20px', paddingBottom: '5px'}}
                        src={toolTip} 
                        alt='tool-tip'
                        title="'Prompts' are instructions for the AI model."
                    ></img>
                </label>
                <textarea
                    className='Text-Prompt-TextArea'
                    value={formData.modeExtraction}
                    onChange={(e) => setFormData({'modeExtraction': e.target.value})}
                ></textarea>

                <label>
                    Content:
                    {/* Tool Tip */}
                    <img 
                        style={{width: '20px', paddingBottom: '5px'}}
                        src={toolTip} 
                        alt='tool-tip'
                        title="'Content' is the information you would like the AI to consume."
                    ></img>
                </label>
                <textarea
                    className='Text-Content-TextArea'
                    placeholder={formData.contentError}
                    value={formData.content}
                    onChange={(e) => setFormData({'content': e.target.value})}
                    onKeyDown={(e) => textHandler(e)}
                ></textarea>
        
            </div>

            <div className='Text-Controls-Container'>

                {/* Modes */}
                <div className='Text-Modes'>
                    <button className='Text-Modes-Tab' onClick={() => setFormData({'mode': 'Summarization'})}>
                        Summarization
                    </button>
                    <button className='Text-Modes-Tab' onClick={() => setFormData({'mode': 'Classification'})}>
                        Classification
                    </button>
                    <button className='Text-Modes-Tab-Active' onClick={() => setFormData({'mode': 'Extraction'})}>
                        Extraction
                    </button>
                </div>

                <div className='Text-Icons'>

                    {
                        // Loading icon --> waiting for requets to be send to Firestore...
                        formData.questionLoading === true ? (

                            <img 
                                className='Text-Loading-Icon' 
                                alt='loading-icon' 
                                src={chatloadingIcon}
                            ></img>

                        ): (

                            // Search & delete thread icon
                            <>
                                <img 
                                    className='Text-Loading-Icon' 
                                    alt='search-icon' 
                                    src={searchIcon}
                                    onClick={() => textHandler('ManualClick')}
                                    title='Submit'
                                ></img>
                                <IconAnimation
                                    className={'Chat-UploadFile-Icon'}
                                    tooltip={'Upload a File'}
                                    onClickHandler={() => setOpenFileModal(true)}
                                    iconDefault={cloudFile}
                                    iconAnimation={uploadFileAnimation}
                                ></IconAnimation>   
                                <IconAnimation
                                    className={'Text-Delete-Icon' }
                                    tooltip={'Delete Thread & Content'}
                                    onClickHandler={() => deleteTextHistory()}
                                    iconDefault={deleteIcon}
                                    iconAnimation={deleteAnimation}
                                ></IconAnimation>     
                            </>

                        )
                    }

                </div>

            </div>

        </div>
    )
}
