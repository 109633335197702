//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

//Contexts
import {GetUser} from '../../Library/GlobalContexts';

//Images
import LendleaseLogo from '../Images/Logo_Lendlease.svg';

//CSS
import './Navbar.css';


export default function Navbar() {
  
  //------------------------------------------------------
  //  react router
  //------------------------------------------------------
  
    const navigate = useNavigate();
    
  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);    

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //State used to determine current view on this page > 'pending', 'success'
    const [componentStatus, setComponentStatus] = useState('pending');

    //Toggles active styles for NavBar
    const navLinkStyles = ({isActive}) => {
      return (
        isActive ? 'Navbar-Items-Container-Link Navbar-Items-Container-Link-Active' : 'Navbar-Items-Container-Link'
      )
    }

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Simple useEffect to ensure a profile exists before showing navbar
    useEffect(() =>{

      if (getUser === undefined) return;

      setComponentStatus('success');
  
    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Waiting for the users context to load...
  if(componentStatus === 'pending'){

    return (

      <>

        {/* Logo */}
        <div className='Navbar-Logo'>
          <NavLink to='/'>
            <img src={LendleaseLogo} alt='Cloud Logo' onClick={() => navigate('/')}></img>
          </NavLink>
        </div>

        {/* Sidebar Items */}
        <div className='Navbar-Items-Container'>
          Loading...
        </div>

      </>
    )
  }

  // Users context has loaded, show the navbar
  else if (componentStatus === 'success'){

      return (

        <>
    
          {/* Logo */}
          <div className='Navbar-Logo'>
            <img src={LendleaseLogo} alt='Cloud Logo'></img>
          </div>
          
          {/* Sidebar Items */}
          <div className='Navbar-Items-Container'>

            {/* Common navs */}
            <NavLink className={navLinkStyles} to='/chat'>
              Chat
            </NavLink>
            <NavLink className={navLinkStyles} to='/text'>
              Text
            </NavLink>
            <NavLink className={navLinkStyles} to='/image'>
              Image
            </NavLink>
            <NavLink className={navLinkStyles} to='/translate'>
              Translate
            </NavLink>
            <NavLink className={navLinkStyles} to='/help'>
              Help
            </NavLink>

          </div>

        </>
      )
    }

}
