//================================================================
//  Page: Support 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';

//Functions

//Images

//CSS
import './Support.css';


export default function Support() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={['isUser', 'isAdmin']}
        requiredRolesValue={true}
        status={'onload'}
        body={
          <div style={{padding: '10px'}}>

            <h3>Lendlease GenAI Portal</h3>
            <p style={{lineHeight: '1.7'}}>
              Artificial Intelligence tools (such as OpenAI's ChatGPT) have become the latest global technology trend, making work and personal life easier, and more productive. Lendlease IT have been working with Google to evaluate how to capitalise on AI, take advantage of the available Google tools, and to provide an 'IP safe' & managed Lendlease alternative to open-market AI solutions.  
            </p>

            <h3 style={{paddingTop:'2%'}}>Data Governance and Lendlease GenAI Portal</h3>
            <div style={{lineHeight: '1.7'}}>
              Google was one of the first in the industry to publish an <a target='_blank' rel='noopener noreferrer' href='https://cloud.google.com/blog/products/ai-machine-learning/google-cloud-unveils-ai-and-ml-privacy-commitment'>AI/ML Privacy Commitment</a>, which outlines our belief that customers should have the highest level of security and control over their data that is stored in the cloud. That commitment extends to Google Cloud generative AI products. 
              Google helps ensure that its teams are following these commitments through robust data governance practices, which includes reviews of the data that Google Cloud uses in the development of its products. 
              More details about how Google processes data can also be found in <a target='_blank' rel='noopener noreferrer' href='https://cloud.google.com/terms/data-processing-addendum'>Google's Customer Data Processing Addendum (CDPA)</a>.
              The solution has been designed to contain all data internally, and data and outputs will only be available to a small number of select users (in IT and P&C)
              <br></br>
              <br></br>
              <h4>Community Practice Guidelines</h4>​
              <a href='https://lendlease.sharepoint.com/sites/LendleaseAICommunityofPractice/SitePages/Guidelines-%26-Governance-for-AI.aspx' target='_blank' rel='noreferrer'>Community Practice Link</a>
              <br></br>
              <br></br>
              <h4>How do we protect Lendlease data?</h4>
              <ul>
                <li>We do not advised the input of sensitive data - such as salary data.</li>
                <li>IT has designed the solution to contain all data internally.</li>
                <li>Data and outputs will only be available to a select group of Lendlease staff (IT and People and Culture).</li>
              </ul>
            </div>

            <h3 style={{paddingTop:'2%'}}>Chat</h3>
            <div style={{lineHeight: '1.7'}}>
              The following are common use cases for chatbots:
              <ul style={{paddingTop:'1%'}}>
                <li><strong>Customer service:</strong> Answer customer questions, troubleshoot issues, and provide information.</li>
                <li><strong>Sales and marketing:</strong> Generate leads, qualify prospects, and answer questions.</li>
                <li><strong>Productivity:</strong> Schedule appointments, create tasks, and find information.</li>
                <li><strong>Education and training:</strong> Assess the level of a student, answer questions, and give feedback.</li>
                <li><strong>Research:</strong> Collect data, conduct surveys, and analyze data.</li>
              </ul>
            </div>

            <h3 style={{paddingTop:'2%'}}>Text</h3>
            <div style={{lineHeight: '1.7'}}>
              You can create text prompts for handling any number of tasks. Some of the most common tasks are classification, summarization, and extraction. You can learn more about designing text prompts for these common tasks in the following pages:
              <ul style={{paddingTop:'1%'}}>
                <li><a href='https://cloud.google.com/vertex-ai/docs/generative-ai/text/classification-prompts' target='_blank' rel='noreferrer'>Classification prompts</a></li>
                <li><a href='https://cloud.google.com/vertex-ai/docs/generative-ai/text/summarization-prompts' target='_blank' rel='noreferrer'>Summarization prompts</a></li>
                <li><a href='https://cloud.google.com/vertex-ai/docs/generative-ai/text/extraction-prompts' target='_blank' rel='noreferrer'>Extraction prompts</a></li>
              </ul>
            </div>

            <h3 style={{paddingTop:'2%'}}>Image</h3>
            <div style={{lineHeight: '1.7'}}>
              To use Imagen on Vertex AI you must provide a text description of what you want to generate or edit. These descriptions are called prompts, and these prompts are the primary way you communicate with the Lendlease GenAI Portal.
              <ul style={{paddingTop:'1%'}}>
                <li>
                  <strong>Identify a goal</strong>
                  <br></br>
                  Depending on your use case, you could have different goals. Are you trying to create an image for a website like an icon, banner, or picture? Are you looking for something for a presentation or blog post? Are you looking to create concept art for a movie or TV show?
                </li>
                <li>
                  <strong>Generate ideas</strong>
                  <br></br>
                  Start by creating a list of ideas. For example, for a presentation, what kind of image would match your content? If you need a graphic, what should it represent?
                </li>
                <li>
                  <strong>Start prompt writing</strong>
                  <br></br>
                  After you've identified more specific details about what you want to create, you can start writing prompts. The structure of a prompt can vary depending on the type of image. A good prompt clearly communicates your vision.
                </li>
                
              </ul>
            </div>

            <h3 style={{paddingTop:'2%'}}>Support</h3>
            <div style={{lineHeight: '1.7'}}>
              Designed and built by Australian Regional IT in collaboration with Google. If you require any assistance or suggestions refer to the below:
              <ul style={{paddingTop:'1%'}}>
                <li><strong>Customer Success Manager:</strong> <a href='mailto:patrick.dooley@lendlease.com'>patrick.dooley@lendlease.com</a></li>
                <li><strong>Software Engineer:</strong> <a href='mailto:mark.bennett@lendlease.com'>mark.bennett@lendlease.com</a></li>
              </ul>
            </div>

          </div>
        }
      ></PageComponent>
    )
}
