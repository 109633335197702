//================================================================
//  Component: Example of component
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - name = {A string, this is the persons name}
//    - message = {A string, a short message}

//  Example:
//    <IconAnimation
//      className={'Chat-UploadFile-Icon'}
//      tooltip={'Upload a File'}
//      onClickHandler={setOpenFileModal(true)}
//      iconDefault={cloudFile}
//      iconAnimation={uploadFileAnimation}
//    ></IconAnimation>    

//================================================================


//Libraries
import React, { useState } from 'react';

export default function IconAnimation({
  className,
  tooltip,
  onClickHandler,
  iconDefault,
  iconAnimation,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [mouseOver, setMouseOver] = useState(false);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <img
      className={className}
      title={tooltip}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={onClickHandler}
      src={mouseOver ? iconAnimation : iconDefault}
      alt={'icon-animation'}
    ></img>
  )
}
