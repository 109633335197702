//================================================================
//  AuthProvider
//  Created by Mark Bennett
//================================================================

//  Purpose: 
//    1. This component resides in index.js
//    2. Handles any auth interactions with FireBase
//    3. Saves all interactions to the global store

//  Example: 
//    ReactDOM.render(

//      <UserContexts>
//        <AuthProvider>
//            <LoginHandler></LoginHandler>
//        </AuthProvider>
//      </UserContexts>,

//    document.getElementById('root')
//    );

//================================================================

//Libraries
// eslint-disable-next-line
import React, { useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged, OAuthProvider } from 'firebase/auth';

//Functions
import GetDocument from './GetDocument';
import WriteDocument from './WriteDocument';
import DocumentListener from './DocumentListener';

//Contexts
import {SetFireBaseProvider, SetFireBaseUser, GetUser, SetUser, GetAppStatus, SetAppStatus, SetAppErrors} from './GlobalContexts';


export default function AuthProvider({children}) {

  //------------------------------------------------------
  //  Firebase
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setFirebaseUser = useContext(SetFireBaseUser);
    const setUser = useContext(SetUser);
    const getUser = useContext(GetUser);
    const setFireBaseProvider = useContext(SetFireBaseProvider);
    const getAppStatus = useContext(GetAppStatus);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Find the users current auth state when the apps auth state changes
  //------------------------------------------------------

    useEffect(() => {

      onAuthStateChanged(auth, (user) =>{

        //Successful sign in 
        if (user){

          //------------------------------------------------------
          //  Save Firebase profile to useContext
          //------------------------------------------------------

          setFirebaseUser(user);

          //------------------------------------------------------
          //  Get or Create user profile
          //------------------------------------------------------

          GetDocument('users', user.email).then((document) =>{

            setUser(document);
            setAppStatus('authenticated');

          }).catch(() =>{

            const document = {
              'emailaddress': user.email,
              'roles': {
                'isUser': true,
                'isAdmin': false
              },
            };

            return WriteDocument('users', user.email, document, false).then(() =>{

              setUser(document);
              setAppStatus('authenticated');

            });

          }).catch((error) =>{

            setAppErrors(`Failed to create user profile, error ${error}`);
            setAppStatus('failed');

          });
       
        // Catch if the app any other state than 'unauthenticated' 
        } else if (getAppStatus !== 'unauthenticated') {

          setAppStatus('unauthenticated');

        }

      });

    // eslint-disable-next-line
    }, []);

    //Save the auth state to session storage
    //This allows us to presist data after refreshes
    useEffect(() => {

      sessionStorage.setItem('getAppStatus', getAppStatus);

    }, [getAppStatus]);

  //------------------------------------------------------
  //  Listens for any changes to to the user collection
  //  Update the user context with current data
  //------------------------------------------------------
  
    useEffect(() => {
      
      if (getAppStatus !== 'authenticated') return;
      if (getUser === undefined) return;

      function onLoadChange(document){

        setUser(document);

      }

      // Handle errors
      function onError(error){

        // User not in Azure AD - this is an issue, so immediately error out
        setAppStatus('failed');
        setAppErrors(error);

      }

      const unsubscribe = DocumentListener('users', getUser.emailaddress, onLoadChange, onLoadChange, onError);

      // Remove document listener if the component 'unMounts'
      return () =>{

        unsubscribe();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAppStatus]);

  //------------------------------------------------------
  //  Define firebase OAuthProvider > 'microsoft.com'
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'OAuthProvider' > 'microsoft.com'
      const Provider = new OAuthProvider('microsoft.com');
      Provider.setCustomParameters(({
        tenant: process.env.REACT_APP_FIREBASE_AZURE_TENANT_ID,
      }));

      //Add scopes
      Provider.addScope('email');
      Provider.addScope('openid');
      Provider.addScope('profile');
      Provider.addScope('User.Read');

      //Save to useContext
      setFireBaseProvider(Provider);

    }, [setFireBaseProvider]);

  //------------------------------------------------------
  //  Pass down all Parent components to childern
  //------------------------------------------------------

    return children;

  //------------------------------------------------------
}
