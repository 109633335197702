//================================================================
//  Chat page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import UploadFileModal from '../Chat/Components/UploadFileModal';
import IconAnimation from '../../Components/IconAnimation/IconAnimation';

//Functions
import queryListener from '../../Library/QueryListener';
import writeDocument from '../../Library/WriteDocument';
import deleteDocument from '../../Library/DeleteDocument';
import uploadFile from '../../Library/UploadFile';

//Images
import submitIcon from '../../Components/Images/Submit-icon.svg';
import chatloadingIcon from '../../Components/Images/Icon_Chat_Loading.gif';
import deleteIcon from '../../Components/Images/Bin-Closed.svg';
import settingsIcon from '../../Components/Images/Settings-Line.svg';
import cloudFile from '../../Components/Images/Cloud-File.svg';
import pdfcsvIcon from '../../Components/Images/PDF_CSV_Icon.svg';
import deleteAnimation from '../../Components/Images/Delete-bin.gif';
import uploadFileAnimation from '../../Components/Images/Upload-file-cloud.gif';

//CSS
import './Chat.css';


export default function Chat() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const setAppErrors = useContext(SetAppErrors);
  const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-other'
  const [pageStatus, setPageStatus] = useState('pending');

  const [chats, setChats] = useState([]);

  // can i use setpage status somehow instead
  const [openFileModal, setOpenFileModal] = useState(false);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      //Question input states
      'question': '',
      'questionError': '',
      'filename': '',

      // File upload states
      'uploadStatus': 'onload', // onload, pending, success, error
      'fileError': '',

    }
  )

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Sends chat request to firestore/Dialog flow
  function chatHandler(e) {

    // Conditions
    if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e !== 'ManualClick') return;

    // Validate input
    if (formData.question.length === 0) {

      return setFormData({ 'questionError': 'Ummmm, are you going to ask me something?' });

    }

    // Reset input
    setFormData({
      'questionError': '',
    });

    // Write question to firestore
    const documentId = `${getUser.emailaddress}-${Date.now()}`;
    const document = {
      'id': documentId,
      'emailaddress': getUser.emailaddress,
      'question': formData.question,
      'status': 'pending', // 'pending', 'complete', 'complete-file', 'error-file'
    };

    writeDocument('chats', documentId, document, false).then(() => {

      setFormData({ 'question': '' });

    }).catch((error) => {

      setFormData({
        'question': '',
        'questionError': 'Unknown error occured while removing history, try again later.',
      });
      setAppErrors(`Failed to delete chat history for user, error: ${error}`);

    });

  };


  // Delete your previous
  function deleteChatHistory() {

    // Hide all chats
    const chatPromises = [];
    chats.forEach((document) => {

      if (document.id === undefined) return;

      chatPromises.push(
        deleteDocument('chats', document.id)
      );

    });

    Promise.all([...chatPromises]).catch((error) => {

      setFormData({
        'question': '',
        'questionError': 'Unknown error occured while removing history, try again later.'
      });
      setAppErrors(`Failed to delete chat history for user, error: ${error}`);

    });

  };

  // Upload file
  function uploadPDFCSV(file) {

    setFormData({ 'uploadStatus': 'pending' });

    // Check if the content is a PDF or CSV
    if (file.type !== 'application/pdf' && file.type !== 'text/csv') {

      return setFormData({
        'uploadStatus': 'error-type',
        'fileError': 'I only support PDF & CSV files. Please try another file.',
      });

    }

    const documentId = `${getUser.emailaddress}-${Date.now()}`;
    const document = {
      'id': documentId,
      'emailaddress': getUser.emailaddress,
      'question': '',
      'status': 'pending-file',
      'filename': file.name
    }

    // Store document in firestore
    writeDocument('chats', documentId, document, false).then(() => {

      let filepath = '';

      if (file.type === 'application/pdf') {

        filepath = `uploads/${getUser.emailaddress}/chat/${documentId}.pdf`;

      } else if (file.type === 'text/csv') {

        filepath = `uploads/${getUser.emailaddress}/chat/${documentId}.csv`;

      }

      // Upload file: success
      return uploadFile(filepath, file).then(() => {

        setFormData({
          'uploadStatus': 'onload',
          'filename': file.name,
        });
        setPageStatus('onload');
        setOpenFileModal(false);

      });

      // Upload file: fail
    }).catch(() => {

      setFormData({
        'uploadStatus': 'error-type',
        'fileError': 'Something went wrong',
      });

    });

  };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Get all chats
  useEffect(() => {

    // Conditions
    if (getUser.emailaddress === undefined) return;

    function onLoadChange(documents) {

      if (documents.length === 0) {

        const welcomeMessage = [
          {
            'id': undefined,
            'emailaddress': getUser.emailaddress,
            'prompt': undefined,
            'answer': `Welcome to Google Cloud 'chat-bison' model.
            \nAsk questions or upload files (PDFs or CSVs) through the below prompt. All generated and uploaded content is secured to your account, and can be deleted at anytime by clicking the bin button.
            \nThanks for stopping by and enjoy :-)`,
            'status': 'complete',
          }
        ]

        setChats(welcomeMessage);
        setFormData({ 'questionError': 'Get started here :-)' });

      } else {

        // Reverse order of docs, so the thread has newest to the bottom.
        setChats(documents.reverse());

      }

      if (pageStatus !== 'onload') return setPageStatus('onload');

    }

    function onError(error) {

      setPageStatus('error-fatal');
      setAppErrors(`Failed to get documents from 'chats' collection, unable to create the document listener error: ${error}`);

    }

    const unsubscribe = queryListener('chats', [
      ['emailaddress', '==', getUser.emailaddress],
    ], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['isUser', 'isAdmin']}
      requiredRolesValue={true}
      status={pageStatus}

      //------------------------------------------------------
      //  Default chat screen
      //------------------------------------------------------

      body={
        <div className='Chat-Form'>

          {/* Thread */}
          <div className='Chat-Thread'>
            {
              chats?.map((chat, index) => (

                <div key={index}>

                  {/* ============================================= */}
                  {/*             Question asked by User            */}
                  {/* ============================================= */}

                  <div className='Chat-Thread-Question-Container' key={`${index}-2`}>
                    {

                      // Normal chats
                      chat.status === 'pending' || chat.status === 'complete' ? (

                        chat.question !== undefined && (
                          <span className='Chat-Thread-Question'>
                            {chat.question}
                          </span>
                        )

                      ) :

                        // File upload in progress
                        chat.status === 'pending-file' ? (

                          <span className='Chat-Thread-Question'>
                            <img className='Chat-Thread-Answer-Loading' alt='chat-loading' src={pdfcsvIcon}></img>
                            <i style={{ 'marginLeft': '10px' }}>Analyzing file, please wait...</i>
                          </span>

                        ) :

                          // File upload completed
                          chat.status === 'complete-file' ? (

                            <span className='Chat-Thread-Question'>
                              <div className='File-Upload-Message-Container'>
                                <img className='File-Upload-Message-File' alt='chat-loading' src={pdfcsvIcon}></img>
                                <div className='File-Upload-Message'>
                                  <b>{chat.filename}</b>
                                  <i>File was successfully proccessed.</i>
                                </div>
                              </div>
                            </span>

                          ) :

                          // File upload completed
                          chat.status === 'error-file' ? (

                            <span className='Chat-Thread-Question'>
                              <div className='File-Upload-Message-Container'>
                                <img className='File-Upload-Message-File' alt='chat-loading' src={pdfcsvIcon}></img>
                                <div className='File-Upload-Message'>
                                  <b>{chat.filename}</b>
                                  <i>Oops, something went wrong :-(</i>
                                </div>
                              </div>
                            </span>

                          ) :

                            // Unknown status from the backend ~ Just hide the chat!
                            (
                              null
                            )

                    }

                  </div>

                  {/* ============================================= */}
                  {/*             Answer provided by AI             */}
                  {/* ============================================= */}

                  <div className='Chat-Thread-Answer-Container' key={`${index}-1`}>
                    {

                      // Pending status for a chat
                      chat.status === 'pending' ? (

                        <img className='Chat-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                        // Successful chat (text or file)
                      ) : chat.status === 'complete' || chat.status === 'complete-file' || chat.status === 'error-file' ? (

                        <span className='Chat-Thread-Answer'>
                          {chat.answer}
                        </span>

                      ) :

                        // 'pending-file' status shouldn't persist after the file is processed
                        chat.status === 'pending-file' ? (

                          <img className='Chat-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                        ) :

                          // Unknown status from the backend ~ Just hide the chat!
                          (

                            null

                          )

                    }
                  </div>

                </div>
              ))
            }
          </div>

          {/* ============================================= */}
          {/*             Input Container                   */}
          {/* ============================================= */}

          {/* Input container on the bottom of the page */}
          <div className='Chat-Input-Container'>

            {/* Chat input box */}
            <input
              className='Chat-Search'
              placeholder={formData.questionError}
              id='question'
              value={formData.question}
              onChange={(e) => {
                setFormData({ 'question': e.target.value })
              }}
              onKeyDown={(e) => chatHandler(e)}
            ></input>

            {/* Search, upload file, delete, and settings thread icon */}
            <img
              className='Chat-Submit-Icon'
              alt='submit-icon'
              src={submitIcon}
              onClick={() => chatHandler('ManualClick')}
              title='Submit'
            ></img>
            <IconAnimation
              className={'Chat-UploadFile-Icon'}
              tooltip={'Upload a File'}
              onClickHandler={() => setOpenFileModal(true)}
              iconDefault={cloudFile}
              iconAnimation={uploadFileAnimation}
            ></IconAnimation>   
            <IconAnimation
              className={'Chat-Delete-Icon'}
              tooltip={'Delete Chat History'}
              onClickHandler={() => deleteChatHistory()}
              iconDefault={deleteIcon}
              iconAnimation={deleteAnimation}
            ></IconAnimation>
            <img
              className='Chat-Settings-Icon'
              alt='settings-icon'
              src={settingsIcon}
              onClick={() => navigate('/chat/settings')}
              title='Settings'
            ></img>

            {/* Upload file modal opens when upload file icon is clicked */}
            <UploadFileModal
              open={openFileModal}
              setOpen={setOpenFileModal}
              uploadPDFCSV={uploadPDFCSV}
              formData={formData}
              setFormData={setFormData}
            ></UploadFileModal>

          </div>

        </div>

      }
    ></PageComponent>
  )
}