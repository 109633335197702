//================================================================
//  Component: PageComponent
//================================================================

//  Purpose:
//    1. Provides a standardised page container that handles styling
//    2. Prevents unauthorized access via a role check
//    3. Advises customers to choose a 'selectedResource'
//    4. Provides standardised 'status' for success pages, errors, etc

//  Properties:
//
//  <REQUIRED>
//    - header = Provide HTML content
//    - body = Provide HTML content
//
//  <OPTIONAL>
//    - requiredRoles = Provide an array of roles. Refer to the predefined 'roles' in the users collection
//    - requiredRolesValue = Provide a string or boolen, the user MUST have this value in ONE of the 'requiredRoles'
//    - status = Provide a useState of the page status, 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
//    - breadcrumb = Provide an Object with the 'name' and 'route', {'name': 'Home', 'route': '/home'}
//    - successContent = Provide a HTML content for the successContent page
//    - errorOtherContent = Provide a HTML content for the error-other page

//  Example:
//
//    <PageComponent
//      header={ HTML Content }
//      body={ HTML Content }
//      requiredRoles={ ['isAdmin'] }
//      requiredRolesValue={ true }
//      status={ 'success' }
//      breadcrumb={ {'name': 'Home', 'route': '/home'} }
//      successContent={ HTML Content }
//      errorOtherContent={ HTML Content }
//    ></PageComponent>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

//Contexts
import {GetUser, GetAppErrors, SetAppErrors} from '../../Library/GlobalContexts';

//Functions
import writeDocument from '../../Library/WriteDocument';

//Components
import Breadcrumbs from '../Breadcrumbs/BreadCrumbs';

//Functions

//Images
import UnauthorisedUser from '../Images/Image_unauthorised_User.svg';
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';
import GrumpyFatal from '../Images/GrumpyCat.png';
import ErrorFatal from '../Images/Image_Error_Fatal.svg';

//CSS is handled in index.css

export default function PageComponent({
  header,
  body,
  requiredRoles,
  requiredRolesValue,
  status,
  breadcrumb,
  successContent,
  errorOtherContent,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getAppErrors = useContext(GetAppErrors);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Handles what the user will see > 'pending', 'selectview', 'selectresource', 'accessdenied', 'invalidenvironment', 'complete' (catch all!)
    const [pageStatus, setPageStatus] = useState('pending');

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> validate if the page can be loaded
    useEffect(()=>{

      if (getUser?.roles === undefined) return;

      // Extract the current view, resource and roles
      const userRoles = getUser.roles;

      //------------------------------------------------------
      // Validate users roles
      //------------------------------------------------------

      if (requiredRoles !== undefined && requiredRoles?.length > 0) {

        let hasRole = false;

        // Loop each of the provided roles
        requiredRoles?.forEach(role => {

          // Check if 'requiredRolesValue'exists in the provided roles
          if (userRoles[role] === requiredRolesValue) {

            hasRole = true;

          } 

        });

        if (hasRole === false) {

          return setPageStatus('accessdenied');

        }

      }

      //------------------------------------------------------
      // All validations are completed
      //------------------------------------------------------

      return setPageStatus('complete');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // Error hander --> Write any clientside errors to firestore
    //Checks for getAppErrors mesages and writes them to the 'failures' collection in Firestore
    useEffect(() => {

      if (getAppErrors === undefined) return;
      if (getUser === undefined) return;
      if (status !== 'error-fatal') return;

      const documentId = `${Date.now()}`;
      const document ={
        id: `${documentId}`,
        emailaddress: `${getUser.emailaddress}`,
        message: `${getAppErrors}`
      };

      //Send and forget > the unknown error could be prevent the user from accessing Firestore!
      writeDocument('failures', documentId, document, false);

      //Clear any old message
      setAppErrors(undefined);

    // eslint-disable-next-line 
    }, [getAppErrors]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //============================
    // Pending
    //============================

    if (pageStatus === 'pending'){
      return null;
    }

    //============================
    // Access denied > user doesn't have the correct role
    //============================

    else if (pageStatus === 'accessdenied'){
      return (
        <>
    
          {/* Breadcrumbs */}
          {
            breadcrumb !== undefined &&

            <div className='PageComponent-Header'>
              <Breadcrumbs
                pageName={breadcrumb?.name}
                pageRoute={breadcrumb?.route}
                pageView={breadcrumb?.view}
                pageResource={breadcrumb?.resource}
              ></Breadcrumbs>

              {header}
            </div>
          }
    
          <>
            <div className='PageComponent-AccessDenied'>

              <img alt="Error Fatal" src={UnauthorisedUser}></img>
              <div style={{fontSize: 'var(--fontsize-subheader)'}}>
                Sorry it seems like you do not have the necessary permissions to access this application. 
              </div>
              <br></br>
              If you feel this is a mistake, reach out to <a rel='noreferrer' href='mailto:mark.bennett@lendlease.com'>mark.bennett@lendlease.com</a>
            </div>

          </>
    
        </>
      )
    }

    //============================
    // All validation checks were passed, load the page!
    //============================

    else {

      return (
        <>

          {/* Breadcrumbs */}
          {
            breadcrumb !== undefined &&

            <div className='PageComponent-Header'>
              <Breadcrumbs
                pageName={breadcrumb?.name}
                pageRoute={breadcrumb?.route}
                pageView={breadcrumb?.view}
                pageResource={breadcrumb?.resource}
              ></Breadcrumbs>
            </div>
          }
    
          <>

            {

              //============================
              // Pending page
              //============================

              status === 'pending' ? (

                <>
                  {header}
                  <div className='PageComponent-Errors-Container'>
                    <img alt="loading-circle-icon" src={LoadingIcon}></img>
                  </div>
                </>

              )
              

              //============================
              // Success page
              //============================

              : status === 'success' ? (
                <>
                  {header}
                  {successContent}
                
                </>

              )


              //============================
              // Error Other page
              //============================

              : status === 'error-other' ? (

                <>
                  {errorOtherContent}
                </>
                
              )


              //============================
              // Error-Invalid page
              //============================

              : status === 'error-invalid' ? (

                <div className='PageComponent-Errors-Container'>
                  <img alt="Error Fatal" src={GrumpyFatal}></img>
                  <h2>Oops something went wrong</h2>
                  <p>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                  </p>
                  <p > 
                    <b>Message:</b> {getAppErrors}
                  </p>
                  <Link to="/home">
                    <button className="Primary-Button">Return Home</button>
                  </Link>
                </div>

              )


              //============================
              // Error-Fatal or Error-Timeout page
              //============================

              : status === 'error-fatal' || status === 'error-timeout' ? (

                <div className='PageComponent-Errors-Container'>
                  <img alt="Error Fatal" src={ErrorFatal}></img>
                  <h2>Oops something went wrong</h2>
                  <p>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                  </p>
                  <p> 
                    <b>Message:</b> Failed to load the page - Fatal Error.
                  </p>
                  <Link to="/home">
                    <button className="Primary-Button">Return Home</button>
                  </Link>
                </div>

              )


              //============================
              // Catch all > show the default body
              //============================

              : status === 'onload' ? (

                <>
                  {header}
                  {body}
                </>

              ) : (

                <>
                  {header}
                  {body}
                </>

              )
            }
          </>

        </>
      )
    }
  
}
