//================================================================
//  Page: Example 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useReducer, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components


//Functions
import queryListener from '../../../Library/QueryListener';
import writeDocument from '../../../Library/WriteDocument';

//Images
import ErrorIcon from '../../../Components/Images/Icon_Failed_Pink.svg';

//CSS
import '../ChatSettings.css';


export default function Temperature({
    collectionId,
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            // onload, pending, error
            'status': 'onload',

            'context': '',
            'temperature': '20',
            'tooltip': false,
        }
    );


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function update(){

        setFormData({'status': 'pending'});

        let temperature;
        if (formData.temperature !== undefined) {

            temperature = parseInt(formData.temperature) /100;
        
        } else {

            temperature = 0.2;

        };

        const doc = {
            'id': getUser.emailaddress,
            'emailaddress': getUser.emailaddress,
            'context': formData.context,
            'temperature': temperature,
        };

        writeDocument(collectionId, getUser.emailaddress, doc, true).then(() => {

            // Success --> Reset form and inputs
            formData.status = 'onload';
            setFormData(formData);

        }).catch(() => {
            
            // Failed --> Change to 'error' state
            formData.status = 'error';
            setFormData(formData);

        });
    }


    // Document listener for contexts
    useEffect(()=>{

        function onLoadChange(documents){

            // No existing document, apply the generic template
            if (documents.length === 0) {

                const template = {
                    'id': getUser.emailaddress,
                    'emailaddress': getUser.emailaddress,
                    'context': 'You are an AI Assistant called "Ask Lendlease". You are helpful and factual. You have a particular expertise in lendlease, however you know all sorts of things.',
                    'temperature': 0.2,
                };

                return writeDocument(collectionId, template.id, template, false);

            };

            formData.context = documents[0].context;
            formData.temperature = Math.round(documents[0].temperature * 100);
            setFormData(formData);

        }
  
        function onError(error){
  
            // Failed --> Change to 'error' state
            console.log(`ERROR ~ ${error}`);
            formData.status = 'error';
            formData.context = 'Failed to load context, please refresh the page and try again.';
            formData.temperature = 20;
            formData.preventSubmit = true
            setFormData(formData);
        
        }

        const unsubscribe = queryListener('context', [
            ['id', '==', getUser.emailaddress],
            ['emailaddress', '==', getUser.emailaddress],
          ], onLoadChange, onLoadChange, onError);
    
        return () =>{
          unsubscribe();
        };
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Initial state of form
    if (formData.status === 'onload') {

        return (

            <div className='ChatSettings-Temperature-Container'>
                <input 
                    className='ChatSettings-Slider' 
                    type='range' 
                    min='0' 
                    max='100'
                    value={formData.temperature} 
                    onChange={(e) => setFormData({'temperature': e.target.value})}
                    onMouseUp={() => update()}
                >
                </input>
                <span>{formData.temperature}%</span>
            </div>
        )

    }

    // Pending
    if (formData.status === 'pending') {

        return (

            <div className='ChatSettings-Temperature-Container'>
                <input 
                    className='ChatSettings-Slider' 
                    type='range' 
                    min='0' 
                    max='100'
                    value={formData.temperature} 
                    onChange={(e) => setFormData({'temperature': e.target.value})}
                    onMouseUp={() => setFormData({'status': 'pending'})}
                    disabled
                >
                </input>
                <span>{formData.temperature}%</span>
            </div>

        )
    }

    // Error
    if (formData.status === 'error') {

        return (

            <div className='ChatSettings-Temperature-Container'>

                <img 
                    alt='error-icon'
                    src={ErrorIcon}
                    style={{width: '20px', marginRight: '10px'}}
                ></img>
                <strong>Oops, something went wrong! Refresh the page and try again.</strong>

            </div>

        )
    }

}