//================================================================
//  Convert a file input to a base64 string
//  Created by Mark Bennett
//================================================================

//  Documentation:
//    - https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
//    - https://pqina.nl/blog/convert-a-file-to-a-base64-string-with-javascript/#encoding-the-file-as-a-base-string


//  Purpose: 
//    - Generic function convert a file input to a base64 string
//    - returns a promise


//  Example:
//
//      const file = e.target.files[0];
//
//      filetoBase64String(file).then((base64string) => {
//      
//         console.log('base64string', base64string);
//      
//      });

//================================================================

export default async function FiletoBase64String(file){

    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            reader.result.replace('data:', '').replace(/^.+,/, '')
        );
        reader.onerror = reject;

    });

};

//--------------------------------------------------------