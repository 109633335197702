//================================================================
//  Image page
//================================================================

//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images
import chatloadingIcon from '../../../Components/Images/Icon_Chat_Loading.gif';

//CSS
import '../Image.css';


export default function ImageThread({
  currentThread,
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <div className='Image-Thread'>
      {
        currentThread?.map((chat, index) => (
          
          <div key={index}>

            {/* ============================================= */}
            {/*             Question asked by User            */}
            {/* ============================================= */}

            <div className='Image-Thread-Question-Container' key={`${index}-2`}>
              {

                chat.prompt !== undefined && (
                  <span className='Image-Thread-Question'>
                    {chat.prompt}
                  </span>
                )

              } 
              
            </div>

            {/* ============================================= */}
            {/*             Answer provided by AI             */}
            {/* ============================================= */}

            <div className='Image-Thread-Answer-Container' key={`${index}-1`}>
              {
              
                // Pending
                chat.status === 'pending' || chat.status === 'pending-edit' ? (

                  <img className='Image-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                // Failed Image Gen
                ): chat.status === 'complete-text' ? (

                  <span className='Image-Thread-Answer-Error'>
                    {chat.answer}
                  </span>

                ): 

                // Successful Image Gen
                chat.status === 'complete-image' ? (

                  // We only maintain the signed url link for 60 minutes
                  chat.ttl > Date.now() ? (

                    <span className='Image-Thread-Answer'>
                      <a href={chat.answer} target='_blank' rel='noreferrer'>
                        <img src={chat.answer} alt='GenAI'></img>
                      </a>
                    </span>

                  ):(
                    <span className='Image-Thread-Answer-Error'>
                      {
                        `Image has been deleted.`
                      }
                    </span>
                  )

                ): 

                // Unknown status from the backend ~ Just hide the chat!
                (
              
                  null

                )

              }
            </div>

          </div>
        ))
      }
    </div>
  )
}
