//================================================================
//  Image page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';

//Contexts
import { GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import Generate from './Components/Generate';
import Edit from './Components/Edit';
import UploadImageModal from './Components/UploadImageModal';

//Functions
import queryListener from '../../Library/QueryListener';

//Images

//CSS
import './Image.css';


export default function Image() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-other'
    const [pageStatus, setPageStatus] = useState('pending');

    // Different modes have different threads, this holds there state information
    const [genThread, setGenThread] = useState([]);
    const [editThread, setEditThread] = useState([]);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {

        //Mode states
        'mode': 'generate', //generate & edit
      
        //Prompt states
        'prompt': '',
        'promptError': '',

        //Upload Image Modal states
        'openImageModal': false,
        'base64string': '',
        'uploadStatus': 'onload', // 'onload' & 'error'
        'imageError': '',

      }
    );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onLoad
    // 1. Setup a query listener
    // 2. Save docs to 'genThread'
    useEffect(()=>{

      // Conditions
      if (getUser.emailaddress === undefined) return;
  
      function onLoadChange(documents){

        if (documents.length === 0) {

          const welcomeMessage = [
            {
              'id': undefined,
              'emailaddress': getUser.emailaddress,
              'prompt': undefined,
              'answer': `Welcome to Google Cloud 'imagegeneration' model.
              \nCreate images through the below prompt. Generated images are shareable with anyone, like a OneDrive link. However after one hour the image will be deleted.
              \nThanks for stopping by and enjoy :-)`,
              'status': 'complete-text',
              'hidden': false,
            }
          ];

          setFormData({
            'promptError': 'Get started here :-)'
          });
          setGenThread([...welcomeMessage]);
  
        } else {

          // Reverse order of docs, so the thread has newest to the bottom.
          setGenThread([...documents].reverse());

        };

        // Reset the page if it was loading
        if (pageStatus !== 'onload') return setPageStatus('onload');

      }
  
      function onError(error){

        setPageStatus('error-fatal');
        setAppErrors(`Failed to get documents from 'currentThread' collection, unable to create the document listener error: ${error}`); 
       
      }
  
      const unsubscribe = queryListener('images', [
        ['emailaddress', '==', getUser.emailaddress],
        ['requesttype', '==', 'generate'],
        ['hidden', '==', false],
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // onLoad
    // 1. Setup a query listener
    // 2. Save docs to 'editThread'
    useEffect(()=>{

      // Conditions
      if (getUser.emailaddress === undefined) return;
  
      function onLoadChange(documents){

        if (documents.length === 0) {

          const welcomeMessage = [
            {
              'id': undefined,
              'emailaddress': getUser.emailaddress,
              'prompt': undefined,
              'answer': `Welcome to Google Cloud 'imagegeneration' model.
              \nUpload and edit images through the below prompt. Generated images are shareable with anyone, like a OneDrive link. However after one hour the image will be deleted.
              \nThanks for stopping by and enjoy :-)`,
              'status': 'complete-text',
              'hidden': false,
            }
          ];

          setEditThread([...welcomeMessage]);
          setFormData({
            'imageError': 'Upload an image to get started :-)'
          });
  
        } else {

          // Reverse order of docs, so the thread has newest to the bottom.
          setEditThread([...documents].reverse());

        };

        // Reset the page if it was loading
        if (pageStatus !== 'onload') return setPageStatus('onload');

      }
  
      function onError(error){

        setPageStatus('error-fatal');
        setAppErrors(`Failed to get documents from 'currentThread' collection, unable to create the document listener error: ${error}`); 
       
      }
  
      const unsubscribe = queryListener('images', [
        ['emailaddress', '==', getUser.emailaddress],
        ['requesttype', '==', 'edit'],
        ['hidden', '==', false],
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={['isUser', 'isAdmin']}
        requiredRolesValue={true}
        status={pageStatus}

        //------------------------------------------------------
        //  Generate chat screen
        //------------------------------------------------------

        body={
          <div className='Image-Form'>

            {/* ============================================= */}
            {/*                  Threads                      */}
            {/* ============================================= */}

            <Generate
              formData={formData}
              setFormData={setFormData}
              genThread={genThread}
            ></Generate>
            <Edit
              formData={formData}
              setFormData={setFormData}
              editThread={editThread}
            ></Edit>

            {/* ============================================= */}
            {/*                 Modals                        */}
            {/* ============================================= */}

            <UploadImageModal
              formData={formData}
              setFormData={setFormData}
            ></UploadImageModal>   
          
          </div>

        }

      ></PageComponent>
    )
}