//================================================================
//  Text page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';

//Contexts
import { GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import Summarization from './Components/Summarization';
import Classification from './Components/Classification';
import Extraction from './Components/Extraction';
import UploadFileModal from './Components/UploadFileModal';

//Functions
import queryListener from '../../Library/QueryListener';
import writeDocument from '../../Library/WriteDocument';
import DeleteDocument from '../../Library/DeleteDocument';
import uploadFile from '../../Library/UploadFile';

//Images
import chatloadingIcon from '../../Components/Images/Icon_Chat_Loading.gif';

//CSS
import './Text.css';


export default function Text() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-other'
    const [pageStatus, setPageStatus] = useState('pending');

    const [texts, setTexts] = useState([]);

    const [openFileModal, setOpenFileModal] = useState(false);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {

        //Mode states
        'mode': 'Summarization', //Summarization, Classification & Extraction
        'modeSummarization': "Provide a summary for the following text:",
        'modeClassification': "For the given text, return a JSON object that has the fields 'Australia' and 'SUV'. Acceptable values for text are 'Exists' or 'Not-Exists'.",
        'modeExtraction': "Extract 'brands' from the text below in a JSON format. Valid fields are 'name', 'country'.",

        //Raw firestore data
        'textsCollection': [],

        //Prompt states
        'content': '',
        'contentError': '',

        // File upload states
        'uploadStatus': 'onload', // onload, pending, success, error
        'filename': '',
        'fileError': '',

      }
     )


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Sends chat request to firestore/Dialog flow
    function textHandler(e) {

      // Conditions
      if (e !== 'ManualClick') return;

      // Validate input
      if (formData.content.length === 0) {

        return setFormData({'contentError': 'Ummm, are you going to provide any content? I am an smart, but not a mind reader ;-)'});

      }

      // Reset input
      setFormData({
        'contentError': '',
      });

      // Write question to firestore
      const documentId = `${getUser.emailaddress}-${Date.now()}`;
      let document = {
        'id': documentId,
        'emailaddress': getUser.emailaddress,
        'prompt': 'TBD',
        'question': 'TBD',
        'status': 'pending',
        'hidden': false,
      }

      // Based off the mode, update the 'prompt' been sent to GenAI
      if (formData.mode === 'Summarization') {

        document.prompt = `${formData.modeSummarization}
        \n${formData.content}`;
        document.question = formData.modeSummarization;

      } else if (formData.mode === 'Classification') {

        document.prompt = `${formData.modeClassification}
        \n${formData.content}`;
        document.question = formData.modeClassification;
        
      } else if (formData.mode === 'Extraction') {

        document.prompt = `${formData.modeExtraction}
        \n${formData.content}`;
        document.question = formData.modeExtraction;
        
      };

      writeDocument('texts', documentId, document, false).catch((error) => {

        setFormData({
          'contentError': 'Unknown error occured, try again later.'
        });
        setAppErrors(`Failed to submit content for user, error: ${error}`);

      });

    };

    // Delete your previous
    function deleteTextHistory(){

      // Hide all prompts
      const chatPromises = [];
      formData.textsCollection.forEach((document) => {

        if (document.id === undefined) return;

        chatPromises.push(
          DeleteDocument('texts', document.id)
        );

      });

      // Reset 'Content' section
      setFormData({
        'content': '',
        'contentError': '',
      });
      
      Promise.all(chatPromises).catch((error) => {

        setFormData({
          'contentError': 'Unknown error occured while removing history, try again later.'
        });
        setAppErrors(`Failed to delete content for user, error: ${error}`);

      });

    };

  // Upload file
  function uploadPDFCSV(file) {

    setFormData({ 'uploadStatus': 'pending' });

    // Check if the content is a PDF or CSV
    if (file.type !== 'application/pdf' && file.type !== 'text/csv') {

      return setFormData({
        'uploadStatus': 'error-type',
        'fileError': 'I only support PDF & CSV files. Please try another file.',
      });

    }

    const documentId = `${getUser.emailaddress}-${Date.now()}`;
    const document = {
      'id': documentId,
      'emailaddress': getUser.emailaddress,
      'question': '',
      'status': 'pending-file',
      'filename': file.name,
      'hidden': false,
    };

    // Store document in firestore
    writeDocument('texts', documentId, document, false).then(() => {

      let filepath = '';

      if (file.type === 'application/pdf') {

        filepath = `uploads/${getUser.emailaddress}/text/${documentId}.pdf`;

      } else if (file.type === 'text/csv') {

        filepath = `uploads/${getUser.emailaddress}/text/${documentId}.csv`;

      }

      // Upload file: success
      return uploadFile(filepath, file).then(() => {

        setFormData({
          'uploadStatus': 'onload',
          'filename': file.name,
        });
        setPageStatus('onload');
        setOpenFileModal(false);

      });

      // Upload file: fail
    }).catch(() => {

      setFormData({
        'uploadStatus': 'error-type',
        'fileError': 'Something went wrong',
      });

    });

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    //  1. Get all text documents
    //  2. No documents prsent a default message
    //  3. Save docs to useState
    useEffect(()=>{

      // Conditions
      if (getUser.emailaddress === undefined) return;
  
      function onLoadChange(documents){

        // Save the raw Firestore data
        setFormData({'textsCollection': documents});

        if (documents.length === 0) {

          const welcomeMessage = [
            {
              'id': undefined,
              'emailaddress': getUser.emailaddress,
              'prompt': undefined,
              'answer': `Welcome to Google Cloud 'text-bison' model.
              \nCreate 'Prompts' and provide "Content" and I can perform analyse through 'Summarization', 'Classification' & 'Extraction' prompts.
              \nThanks for stopping by and enjoy :-)`,
              'status': 'complete',
              'hidden': false,
            }
          ]
          setTexts(welcomeMessage.reverse());

          setFormData({
            'contentError': 'Paste in content or select the file upload icon.'
          });
  
        } else {

          // Reverse order of docs, so the thread has newest to the bottom.
          setTexts(documents.reverse());

        };

        // Reset the page if it was loading
        if (pageStatus !== 'onload') return setPageStatus('onload');

      }
  
      function onError(error){

        setPageStatus('error-fatal');
        setAppErrors(`Failed to get documents from 'texts' collection, unable to create the document listener error: ${error}`); 
       
      }
  
      const unsubscribe = queryListener('texts', [
        ['emailaddress', '==', getUser.emailaddress],
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


    // OnLoad
    //  1. Get file content
    //  2. Save to content
    //  3. Update status of file
    useEffect(()=>{

      // Conditions
      if (getUser.emailaddress === undefined) return;
  
      function onLoadChange(documents){

        if (documents.length === 0) return;

        setFormData({
          'content': documents[0]?.content,
          'contentError': documents[0]?.answer,
        });

        const document = {
          'status': 'complete'
        };
        writeDocument('texts', documents[0].id, document, true).catch(() => {

          setFormData({
            'content': '',
            'contentError': 'Unknown error has occured, refresh the page and try again.',
          });

        });

      };

      function onError(error){

        setPageStatus('error-fatal');
        setAppErrors(`Failed to get documents from 'texts' collection, unable to create the document listener error: ${error}`); 
       
      }
  
      const unsubscribe = queryListener('texts', [
        ['emailaddress', '==', getUser.emailaddress],
        ['status', 'in', ['complete-file', 'error-file']],
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requiredRoles={['isUser', 'isAdmin']}
        requiredRolesValue={true}
        status={pageStatus}

        //------------------------------------------------------
        //  Default chat screen
        //------------------------------------------------------

        body={
          <div className='Text-Form'>

            {/* Thread */}
            <div className='Text-Thread'>
              {
                texts?.map((chat, index) => (
                  
                  <div key={index}>

                    {/* ============================================= */}
                    {/*             Question asked by User            */}
                    {/* ============================================= */}

                    <div className='Text-Thread-Question-Container' key={`${index}-2`}>
                      {

                        chat.prompt !== undefined && (
                          <span className='Text-Thread-Question'>
                            {chat.question}
                          </span>
                        )

                      } 
                      
                    </div>

                    {/* ============================================= */}
                    {/*             Answer provided by AI             */}
                    {/* ============================================= */}

                    <div className='Text-Thread-Answer-Container' key={`${index}-1`}>
                      {
                      
                        // Pending status for a chat
                        chat.status === 'pending' || chat.status === 'pending-file' ? (

                          <img className='Text-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                        // Successful chat text
                        ): chat.status === 'complete-file' ? (

                          <span className='Text-Thread-Answer'>
                            {chat.answer}
                          </span>
  
                        ): 

                        // Successful chat text
                        chat.status === 'complete' ? (

                          <span className='Text-Thread-Answer'>
                            {chat.answer}
                          </span>
  
                        ): 

                        // Unknown status from the backend ~ Just hide the chat!
                        (
                      
                          null

                        )

                      }
                    </div>


                  </div>
                ))
              }
            </div>


            {/* =========================================== */}
            {/*                    Modes                    */}
            {/* =========================================== */}

            <Summarization
              formData={formData}
              setFormData={setFormData}
              textHandler={textHandler}
              deleteTextHistory={deleteTextHistory}
              setOpenFileModal={setOpenFileModal}
            ></Summarization>
            <Classification
              formData={formData}
              setFormData={setFormData}
              textHandler={textHandler}
              deleteTextHistory={deleteTextHistory}
              setOpenFileModal={setOpenFileModal}
            ></Classification>
            <Extraction
              formData={formData}
              setFormData={setFormData}
              textHandler={textHandler}
              deleteTextHistory={deleteTextHistory}
              setOpenFileModal={setOpenFileModal}
            ></Extraction>
          

            {/* Upload file modal opens when upload file icon is clicked */}
            <UploadFileModal
              open={openFileModal}
              setOpen={setOpenFileModal}
              uploadPDFCSV={uploadPDFCSV}
              formData={formData}
              setFormData={setFormData}
            ></UploadFileModal>

          </div>

        }

      ></PageComponent>
    )
}