//================================================================
//  Application: Events App 
//================================================================

//  Product Owner: Scott Wrigley
//  Created 11/04/2022

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts

//Pages
import Chat from './Pages/Chat/Chat';
import ChatSettings from './Pages/ChatSettings/ChatSettings';
import Text from './Pages/Text/Text';
import Image from './Pages/Image/Image';
import Support from './Pages/Support/Support';
import Translate from './Pages/Translate/Translate';
import NotFound from './Pages/NotFound/NotFound';

//Components
import Navbar from './Components/Navbar/Navbar';
import DisclaimerModal from './Components/DisclaimerModal/DisclaimerModal';

//Styles
import './App.css'
// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <div className='App-Container'>

        {/* Nav */}
        <div className='App-Container-Nav'>
          <Navbar></Navbar>
        </div>

        {/* Disclaimer & Quota Limit notice */}
        {
          process.env.REACT_APP_QUOTA_ENABLED === 'YES' && (
            <DisclaimerModal></DisclaimerModal>
          )
        }

        {/* Body > Contains all pages in the app  */}
        <div className='App-Container-Body'>

          <Routes>           

            {/* Common routes */}
            <Route path='/' element={<Chat/>}/>
            <Route path='/chat' element={<Chat/>}/>
            <Route path='/chat/settings' element={<ChatSettings/>}/>
            <Route path='/text' element={<Text/>}/>
            <Route path='/image' element={<Image/>}/>
            <Route path='/translate' element={<Translate/>}/>
            <Route path='/help' element={<Support/>}/>

            {/* App routes */}
            <Route path='*' element={<NotFound />}></Route>

          </Routes>
        </div>

      </div>
    )

    //------------------------------------------------------
}
