//==========================================================================
//   Function: Delete Document
//   Description: Deletes a document from a collection
//   Use Case: Use this function when you want to delete an existing document within a collection
//   Documentation:
//   - https://firebase.google.com/docs/firestore/manage-data/delete-data
//==========================================================================

//   Example - How to use this function: 
//
//   DeleteDocument('user', userId)
//   .then(() =>{

//     // Change page request until success
//      setRequestType('view');

//   }).catch((error) => {
//       console.log(' - Failed to delete user document: ", error)
//   })

//------------------------------------------------------


//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { doc, deleteDoc } from "firebase/firestore"; 


export default async function DeleteDocument(collection, documentId){

    //Delete an existing document
    try {

      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection & document
      const collectionDocRef = doc(db, collection, documentId);

     // Delete the document
      return deleteDoc(collectionDocRef);

    } catch (error) {
      throw new Error(`Function DeleteDocument failed to complete, error ${error}`)
    }
}