//================================================================
//  Page: Example 
//================================================================

//  Purpose: This is the standard for creating a page

//  Supporting documentation
//    - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223229935730/How+we+build+initiatives+for+the+AU+Region#React

//================================================================

//Libraries
import React, { useReducer, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components


//Functions
import queryListener from '../../../Library/QueryListener';
import writeDocument from '../../../Library/WriteDocument';


//Images
import SaveIcon from '../../../Components/Images/Icon_Save_Blue.svg'; 
import LoadingIcon from '../../../Components/Images/Icon_Loading_Black.svg';
import DeleteIcon from '../../../Components/Images/Icon_Delete_Blue.svg';
import ErrorIcon from '../../../Components/Images/Icon_Failed_Pink.svg';

//CSS
import '../ChatSettings.css';



export default function ContextForm({
    collectionId,
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            // onload, pending, saved, error
            'status': 'onload',
            'preventSubmit': true,

            'context': '',
        }
    );


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function addExample(){

        const doc = {
            'id': getUser.emailaddress,
            'emailaddress': getUser.emailaddress,
            'context': formData.context,
        }

        writeDocument(collectionId, getUser.emailaddress, doc, true).then(() => {

            // Success --> Reset form and inputs
            formData.status = 'onload';
            formData.preventSubmit = true
            setFormData(formData);

        }).catch(() => {
            
            // Failed --> Change to 'error' state
            formData.status = 'error';
            formData.preventSubmit = true
            setFormData(formData);

        });
    }

    function deleteExample(){

        const document = {
            'id': `${getUser.emailaddress}`,
            'emailaddress': getUser.emailaddress,
            'context': '', 
        };
        writeDocument(collectionId, getUser.emailaddress, document, true).then(() => {

            // Success --> Reset form and inputs
            formData.context = '';
            formData.status = 'onload';
            formData.preventSubmit = true
            setFormData(formData);

        }).catch(() => {
            
            // Doc doesn't exist or another error --> We don't care, reset form & move on
            formData.context = '';
            formData.status = 'onload';
            formData.preventSubmit = true
            setFormData(formData);

        });

    }

    // Document listener for contexts
    useEffect(()=>{
  
        function onLoadChange(documents){

            // No existing document, apply the generic template
            if (documents.length === 0) {

                const template = {
                    'id': getUser.emailaddress,
                    'emailaddress': getUser.emailaddress,
                    'context': 'You are an AI Assistant called "Ask Lendlease". You are helpful and factual. You have a particular expertise in lendlease, however you know all sorts of things.', 
                };

                return writeDocument(collectionId, template.id, template, false);

            };

            setFormData({'context': documents[0].context});

        }
  
        function onError(error){
  
            // Failed --> Change to 'error' state
            console.log(`ERROR ~ ${error}`);
            formData.status = 'error';
            formData.context = 'Failed to load context, please refresh the page and try again.';
            formData.preventSubmit = true
            setFormData(formData);
        
        }

        const unsubscribe = queryListener('context', [
            ['id', '==', getUser.emailaddress],
            ['emailaddress', '==', getUser.emailaddress],
          ], onLoadChange, onLoadChange, onError);
    
        return () =>{
          unsubscribe();
        };
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Initial state of form
    if (formData.status === 'onload') {

        return (
            <div className='ChatSettings-Context-Container'>
                <textarea 
                    className='ChatSettings-Context' 
                    placeholder='You are an AI Assistant called "Ask Lendlease". You are helpful and factual. You have a particular expertise in lendlease, however you know all sorts of things.'
                    value={formData.context}
                    onChange={(e) => {

                        formData.context = e.target.value;
                        if (formData.context.length) {

                            formData.preventSubmit = false;

                        } else {

                            formData.preventSubmit = true;

                        }
                        setFormData(formData);

                    }}
                ></textarea>

                {
                    formData.preventSubmit === true ? (

                        <div style={{padding: '1px'}}>
                            <img 
                                className='ChatSettings-Icon-Disabled'
                                alt='save-icon'
                                src={SaveIcon}
                                onClick={() => addExample()}
                            ></img>
                            {
                                formData.context.length === 0 ? (

                                    <img 
                                        className='ChatSettings-Icon-Disabled'
                                        alt='delete-icon'
                                        src={DeleteIcon}
                                        style={{marginLeft: '1px'}}
                                        onClick={() => deleteExample()}
                                    ></img>

                                ): (

                                    <img 
                                        className='ChatSettings-Icon'
                                        alt='delete-icon'
                                        src={DeleteIcon}
                                        style={{marginLeft: '1px'}}
                                        onClick={() => deleteExample()}
                                    ></img>

                                )
                            }
                        </div>

                    ) : (

                        <div style={{padding: '1px'}}>
                            <img 
                                className='ChatSettings-Icon'
                                alt='save-icon'
                                src={SaveIcon}
                                onClick={() => addExample()}
                            ></img>
                            <img 
                                className='ChatSettings-Icon'
                                alt='delete-icon'
                                src={DeleteIcon}
                                style={{margin: '1px'}}
                                onClick={() => deleteExample()}
                            ></img>
                        </div>

                    )
                }


            </div>
        )

    }

    // Pending
    if (formData.status === 'pending') {

        return (
            <div className='ChatSettings-Context-Container'>
                <textarea 
                    className='ChatSettings-TextAreas' 
                    placeholder='Type a context here'
                    onChange={(e) => formData.context = e.target.value}
                    disabled
                ></textarea>
                <img 
                    className='ChatSettings-Icon'
                    alt='loading-icon'
                    src={LoadingIcon}
                ></img>
            </div>
        )
    }

    // Error
    if (formData.status === 'error') {

        return (
            <div className='ChatSettings-Context-Container'>

                <textarea 
                    className='ChatSettings-TextAreas' 
                    placeholder='Type a context here'
                    onChange={(e) => formData.context = e.target.value}
                    disabled
                ></textarea>

                <img 
                    className='ChatSettings-Icon'
                    alt='error-icon'
                    src={ErrorIcon}
                    onClick={() => addExample()}
                ></img>
 
            </div>
        )
    }

}