//================================================================
//  Component: Upload File ImageModal
//================================================================

//  Purpose: This is the upload file modal for chat feature

//  Properties:
//    - formData = {useReducer for setting status of file upload}
//    - setFormData = {useReducer for changing status of file upload}

//  <UploadImageModal
//    formData={formData}
//    setFormData={setFormData}
//  ></UploadImageModal>   

//================================================================


//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';


//Functions
import uploadFile from '../../../Library/UploadFile';
import writeDocument from '../../../Library/WriteDocument';
import filetoBase64String from '../../../Library/FiletoBase64String';

//Images
import uploadError from '../../../Components/Images/PDF_Error.svg';
import imageUploadIcon from '../../../Components/Images/Icon_PNG_Upload.svg';
import closeButton from '../../../Components/Images/Close-Button.svg'
import loadingDots from '../../../Components/Images/Loading-Dots.gif'

//CSS
import './UploadImageModal.css';


export default function UploadImageModal({
  formData,
  setFormData
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function uploadImage(file){

      // Check if the content is a PDF or CSV
      if (file.type !== 'image/png') {
        return setFormData({
          'uploadStatus': 'error',
          'imageError': 'I only support PNG files. Please try another file.',
        });
      }

      // Convert the image file to a base64 string
      return filetoBase64String(file).then((base64string) => {

        const documentId = `${getUser.emailaddress}-${Date.now()}`;

        setFormData({'uploadStatus': 'pending'});

        // Upload file
        return uploadFile(`uploads/${getUser.emailaddress}/${documentId}.png`, file).then((link) => {

          // Calulate the images time to live (ttl)
          const imageTTL = 60;
          let ttl = new Date();
          ttl = ttl.setTime(ttl.getTime() + imageTTL * 60 * 1000);

          // Write the document to firestore
          const document = {
            'id': documentId,
            'emailaddress': getUser.emailaddress,
            'prompt': `Uploaded image ${file.name}`,
            'answer': link,
            'status': 'complete-image',
            'requesttype': 'edit',
            'ttl': ttl,
            'hidden': false,
          };
          return writeDocument('images', documentId, document, false).then(() => {

            // Close modal
            setFormData({
              'uploadStatus': 'onload',
              'base64string': base64string,
              'openImageModal': false,
              'promptError': 'Add a hat to this image',
            });

          });

        });

      }).catch(() => {

        setFormData({
          'uploadStatus': 'error',
          'imageError': 'Failed to upload image, try again later.'
        });

      });

    };


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Keeps modal closed while not in use
  if (!formData.openImageModal) return null;

  if (formData.uploadStatus === 'onload'){

    return (
      <div className='ImageModal-Overlay' onClick={() => setFormData({'openImageModal': false})}>

        <dialog className='ImageModal-Container' onClick={(e) => { e.stopPropagation() }}>

          {/* Close Button */}
          <div className='ImageModal-Title'>
            <h5>Upload a image</h5>
            <button
              onClick={() => setFormData({'openImageModal': false})}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}>
              <img src={closeButton} alt={'close-button'}></img>
            </button>
          </div>

          {/* Drop File */}
          <div className='ImageModal-Drag-Container'
            onDragOver={(e) => {

              e.preventDefault();

            }}
            onDrop={(e) => {

              e.preventDefault();
              uploadImage(e.dataTransfer.files[0]);

            }}
          >
            <img src={imageUploadIcon} alt='icon-upload-png'></img>
          </div>
          <b className='ImageModal-OR'>OR</b>

          {/* Choose file button */}
          <div className='ImageModal-Choose-File-Button'>
            <input
              className='ImageModal-Choose-File-Button'
              type='file'
              accept='.png'
              onChange={(e) => {

                uploadImage(e.target.files[0]);

              }}
            ></input>

          </div>

        </dialog>

      </div>
    )
    
  }

  // Modal for pending status - when a file takes a long time to upload - also closes modal when upload successful
  else if (formData.uploadStatus === 'pending') {
    return (
      <div className='Modal-Overlay'>
        <dialog className='Modal-Container'>

          {/* Close Button */}
          <div className='Modal-Title'>
            <h5>Upload a file</h5>
            <button
              onClick={() => setFormData({'openImageModal': false})}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}>
              <img src={closeButton} alt={'close-button'}></img>
            </button>
          </div>

          {/* Loading animation */}
          <div className='Modal-Load-Container'>
            <img className='Modal-File-Loading' src={loadingDots} alt={'file loading'}></img>
          </div>
          <b className='Modal-OR'>
            File uploading...
          </b>
        </dialog>
      </div>
    )
  }

  // Any error in uploading file
  else {
    return (
      <div className='ImageModal-Overlay'>
        <dialog className='ImageModal-Container'>

          {/* Close Button */}
          <div className='ImageModal-Title'>
            <h5>Upload a file</h5>
            <button
              onClick={() => setFormData({'openImageModal': false})}
              style={{width: '35px', background: 'none', borderRadius: '5px'}}>
              <img src={closeButton} alt={'close-button'}></img>
            </button>
          </div>

          {/* Something went wrong error image */}
          <div className='ImageModal-Drag-Container'>
            <img
              alt='pdf-upload-icon'
              src={uploadError}
            ></img>
          </div>
          <b className='ImageModal-OR'>
            {formData.imageError}
          </b>

          {/* Select another file button - takes user back on onload/upload file modal */}
          <button className='ImageModal-Select-Another-File-Button' onClick={() => setFormData(formData.uploadStatus = 'onload')} >Select another file</button>
        </dialog>
      </div>
    )
  }

}
