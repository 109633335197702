//================================================================
//  Image page
//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Components
import CurrentThread from './ImageThread';
import IconAnimation from '../../../Components/IconAnimation/IconAnimation';

//Functions
import writeDocument from '../../../Library/WriteDocument';
import updateDocument from '../../../Library/UpdateDocument';

//Images
import searchIcon from '../../../Components/Images/Icon_Search_Black.svg';
import chatloadingIcon from '../../../Components/Images/Icon_Chat_Loading.gif';
import deleteIcon from '../../../Components/Images/Icon_Delete_Blue.svg';
import deleteAnimation from '../../../Components/Images/Delete-bin.gif';
import editIcon from '../../../Components/Images/Icon_Edit_Blue.svg';

//CSS
import '../Image.css';


export default function Generate({
  formData,
  setFormData,
  genThread,
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Create image prompt handler
    function createPromptHandler(e) {

      // Conditions
      if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e !== 'ManualClick') return;

      // Validate input
      if (formData.prompt.length === 0) {

        return setFormData({ 'promptError': 'Ummmm, are you going to ask me something?' });

      }

      // Reset input
      setFormData({ 'promptError': '' });

      // Write question to firestore
      const documentId = `${getUser.emailaddress}-${Date.now()}`;
      let document = {
        'id': documentId,
        'emailaddress': getUser.emailaddress,
        'prompt': formData.prompt,
        'status': 'pending',
        'requesttype': 'generate',
        'hidden': false,
      };

      writeDocument('images', documentId, document, false).then(() => {

        setFormData({ 'prompt': '' });

      }).catch((error) => {

        setFormData({ 
          'prompt': '',
          'promptError': 'Unknown error occured, try again later.' 
        });
        setAppErrors(`Failed to submit prompt for user, error: ${error}`);

      });

    };
  
    // Delete the current thread
    function deleteImageHistory(){

      // Hide all genThread
      const chatPromises = [];
      genThread.forEach((document) => {

        if (document.id === undefined) return;

        chatPromises.push(
          updateDocument('images', document.id, {
            'hidden': true,
          })
        );

      });

      // Reset the thread
      setFormData({'base64string': ''});

      Promise.all(chatPromises).catch((error) => {

        setFormData({
          'promptError': 'Unknown error occured while removing history, try again later.'
        });
        setAppErrors(`Failed to delete prompt for user, error: ${error}`);

      });

    };
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Hide this input if the mode isn't 'generate'
    if (formData.mode !== 'generate') return null;

    return (

      <>
        <CurrentThread
          currentThread={genThread}
        ></CurrentThread>
      
        <div className='Image-Input-Container'>

          {/* Image input box */}
          <input
            className='Image-Search'
            placeholder={formData.promptError}
            id='prompt'
            value={formData.prompt}
            onChange={(e) => {
              setFormData({ 'prompt': e.target.value })
            }}
            onKeyDown={(e) => createPromptHandler(e)}
          ></input>

          {
            // Loading icon --> waiting for requets to be send to Firestore...
            formData.questionLoading === true ? (

              <img
                className='Image-Loading-Icon'
                alt='loading-icon'
                src={chatloadingIcon}
              ></img>

            ) : (

              // Search, upload file, delete, and settings thread icon
              <div className='Image-Icons'>
                <img
                  alt='search-icon'
                  src={searchIcon}
                  onClick={() => createPromptHandler('ManualClick')}
                  title='Submit'
                ></img>
                <IconAnimation
                    className={'Text-Delete-Icon' }
                    tooltip={'Delete Thread'}
                    onClickHandler={() => deleteImageHistory()}
                    iconDefault={deleteIcon}
                    iconAnimation={deleteAnimation}
                ></IconAnimation> 
                <img
                  alt='edit-icon'
                  style={{padding: '6px'}}
                  src={editIcon}
                  onClick={() => setFormData({'mode': 'edit'})}
                  title='Image editing'
                ></img>

              </div>

            )
          }

        </div>
      </>
    )
}
