//------------------------------------------------------
//  Handler for all login events
//  Designed to handle the login process, handle failures and if success give a user an getAccessToken
//  The 'LoginHandlerComponent' is built to handle both 'authState'
//    'authenticated'
//    'unauthenticated'
//------------------------------------------------------


//Libraries
import React, { useContext } from 'react';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import {GetAppStatus, GetAppErrors} from './GlobalContexts';

//Components
import Login from './Login';
import Logout from './Logout';

//Images
import LoadingIcon from '../Components/Images/Image_Loading_Ripple.svg';
import Logo from '../Components/Images/Logo_Lendlease.svg';

//Styling for login page
import './LoginHandler.css';


function LoginHandler() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus)
    const getAppErrors = useContext(GetAppErrors)

  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === 'authenticated'){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === 'pending'){
  
      return (
        <div className='LoginHandler-Pending'>
          <img alt='loading-circle-icon' src={LoadingIcon}></img>
          <Logout title='Cancel'></Logout>
        </div>
      )
    }

  //------------------------------------------------------
  //  unauthorised
  //------------------------------------------------------

  else if (getAppStatus === 'unauthorised'){
  
    return (
      <div className='LoginHandler-Failed-Container'>

        <img className='LoginHandler-Failed-Img' alt='Logo' src={Logo} width='300px'></img>
      
        <h2 className='LoginHandler-Failed-Title'>Unauthorised</h2>

        <div className='LoginHandler-Failed-Solution'>
          Unfortunately you have not been granted access to this application. If you feel this is a mistake, reach out to <a rel='noreferrer' href='mailto:mark.bennett@lendlease.com'>mark.bennett@lendlease.com</a>.
        </div>

        <div className='LoginHandler-Failed-Error'>
          <Logout title='Return to sign in page'></Logout>

        </div>

      </div>
    )
  
  }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

      //------------------------------------------------------
      //  Handles each known error message, with a generic catch all response!
      //------------------------------------------------------

        function HandleErrorMessage(message){

          //General catch, to ensure something exists
          if (message === undefined) return;

          //Allow third-party cookies
          if (message.includes('web-storage-unsupported')){
            return(
              <div>
                Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/invalid-credential')){
            return(
              <div>
                Failed verify your credentials. Close the browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/popup-closed-by-user')){
            return(
              <div>
                You closed the sign in pop up. Click 'return to the sign in page' and try again.
              </div>
            )
          }

          //User is blocking popups
          if (message.includes('auth/popup-blocked')){
            return(
              <div>
                Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
              </div>
            )
          }

          //There is an existing Firebase account with this users UPN 
          if (message.includes('auth/account-exists-with-different-credential')){
            return(
              <div>
                Found an existing account with the same email address. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }

          //General catch all
          else{
            return(
              <div>
                Unknown issue has occured. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }
        }

      //------------------------------------------------------
      //  Show 'Oops, something went wrong!' page 
      //------------------------------------------------------

        return (
          <div className='LoginHandler-Failed-Container'>

            <img className='LoginHandler-Failed-Img' alt='Logo' src={Logo}></img>
          
            <h2 className='LoginHandler-Failed-Title'>Oops, something went wrong!</h2>

            <div className='LoginHandler-Failed-Solution'>
              {HandleErrorMessage(getAppErrors)}
            </div>

            <div className='LoginHandler-Failed-Button'>
              <Logout title='Return to sign in page'></Logout>
            </div>

            <div className='LoginHandler-Failed-Error'>
              <h3>Error message</h3>
              {getAppErrors}
            </div>

          </div>
        )
    }

  //------------------------------------------------------
  //  Un-Authorised Users
  //------------------------------------------------------

    else if (getAppStatus === 'unauthenticated'){

      return (
        <div className='App'>
          <div className='LoginHandler-Container'>
            {/* Login Graphic */}
            <div>
              <img className='Login-Graphic' src={Logo} alt='Logo'></img>
            </div>

            {/* Login Pane */}
            <div className='Login-Detail'>
              <h3>Lendlease GenAI Portal</h3>
              
              {/* Login Button */}
              <div className='Login-Button'>
                <Login></Login>
              </div>
            
            </div>
          </div>
        </div>
      )

    }

  //------------------------------------------------------
}

export default LoginHandler;