//================================================================
//  Translate page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import IconAnimation from '../../Components/IconAnimation/IconAnimation';
import LanguageIcon from '../../Components/Images/Icon_Language_Blue.svg';

//Functions
import queryListener from '../../Library/QueryListener';
import writeDocument from '../../Library/WriteDocument';
import deleteDocument from '../../Library/DeleteDocument';

//Images
import submitIcon from '../../Components/Images/Submit-icon.svg';
import chatloadingIcon from '../../Components/Images/Icon_Chat_Loading.gif';
import deleteIcon from '../../Components/Images/Bin-Closed.svg';
import deleteAnimation from '../../Components/Images/Delete-bin.gif';

//CSS
import './Translate.css';


export default function Translate() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-other'
  const [pageStatus, setPageStatus] = useState('pending');

  const [chats, setChats] = useState([]);


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {

      //Question input states
      'question': '',
      'questionError': '',
      'targetLanguage': {
        'key': 'Japanese',
        'value': 'ja'
      },

      // Translate States
      'showOptions': false,
      'langaugeCodes': [
        {"key": "Afrikaans", "value":  "af"},
        {"key": "Albanian", "value":  "sq"},
        {"key": "Amharic", "value":  "am"},
        {"key": "Arabic", "value":  "ar"},
        {"key": "Armenian", "value":  "hy"},
        {"key": "Assamese", "value":  "as"},
        {"key": "Aymara", "value":  "ay"},
        {"key": "Azerbaijani", "value":  "az"},
        {"key": "Bambara", "value":  "bm"},
        {"key": "Basque", "value":  "eu"},
        {"key": "Belarusian", "value":  "be"},
        {"key": "Bengali", "value":  "bn"},
        {"key": "Bhojpuri", "value":  "bho"},
        {"key": "Bosnian", "value":  "bs"},
        {"key": "Bulgarian", "value":  "bg"},
        {"key": "Catalan", "value":  "ca"},
        {"key": "Cebuano", "value":  "ceb"},
        {"key": "Chinese (Simplified)", "value":  "zh-CN"},
        {"key": "Chinese (Traditional)", "value":  "zh-TW"},
        {"key": "Corsican", "value":  "co"},
        {"key": "Croatian", "value":  "hr"},
        {"key": "Czech", "value":  "cs"},
        {"key": "Danish", "value":  "da"},
        {"key": "Dhivehi", "value":  "dv"},
        {"key": "Dogri", "value":  "doi"},
        {"key": "Dutch", "value":  "nl"},
        {"key": "English", "value":  "en"},
        {"key": "Esperanto", "value":  "eo"},
        {"key": "Estonian", "value":  "et"},
        {"key": "Ewe", "value":  "ee"},
        {"key": "Filipino (Tagalog)", "value":  "fil"},
        {"key": "Finnish", "value":  "fi"},
        {"key": "French", "value":  "fr"},
        {"key": "Frisian", "value":  "fy"},
        {"key": "Galician", "value":  "gl"},
        {"key": "Georgian", "value":  "ka"},
        {"key": "German", "value":  "de"},
        {"key": "Greek", "value":  "el"},
        {"key": "Guarani", "value":  "gn"},
        {"key": "Gujarati", "value":  "gu"},
        {"key": "Haitian Creole", "value":  "ht"},
        {"key": "Hausa", "value":  "ha"},
        {"key": "Hawaiian", "value":  "haw"},
        {"key": "Hebrew", "value":  "he"},
        {"key": "Hindi", "value":  "hi"},
        {"key": "Hmong", "value":  "hmn"},
        {"key": "Hungarian", "value":  "hu"},
        {"key": "Icelandic", "value":  "is"},
        {"key": "Igbo", "value":  "ig"},
        {"key": "Ilocano", "value":  "ilo"},
        {"key": "Indonesian", "value":  "id"},
        {"key": "Irish", "value":  "ga"},
        {"key": "Italian", "value":  "it"},
        {"key": "Japanese", "value":  "ja"},
        {"key": "Javanese", "value":  "jv"},
        {"key": "Kannada", "value":  "kn"},
        {"key": "Kazakh", "value":  "kk"},
        {"key": "Khmer", "value":  "km"},
        {"key": "Kinyarwanda", "value":  "rw"},
        {"key": "Konkani", "value":  "gom"},
        {"key": "Korean", "value":  "ko"},
        {"key": "Krio", "value":  "kri"},
        {"key": "Kurdish", "value":  "ku"},
        {"key": "Kurdish (Sorani)", "value":  "ckb"},
        {"key": "Kyrgyz", "value":  "ky"},
        {"key": "Lao", "value":  "lo"},
        {"key": "Latin", "value":  "la"},
        {"key": "Latvian", "value":  "lv"},
        {"key": "Lingala", "value":  "ln"},
        {"key": "Lithuanian", "value":  "lt"},
        {"key": "Luganda", "value":  "lg"},
        {"key": "Luxembourgish", "value":  "lb"},
        {"key": "Macedonian", "value":  "mk"},
        {"key": "Maithili", "value":  "mai"},
        {"key": "Malagasy", "value":  "mg"},
        {"key": "Malay", "value":  "ms"},
        {"key": "Malayalam", "value":  "ml"},
        {"key": "Maltese", "value":  "mt"},
        {"key": "Maori", "value":  "mi"},
        {"key": "Marathi", "value":  "mr"},
        {"key": "Meiteilon (Manipuri)", "value":  "mni-Mtei"},
        {"key": "Mizo", "value":  "lus"},
        {"key": "Mongolian", "value":  "mn"},
        {"key": "Myanmar (Burmese)", "value":  "my"},
        {"key": "Nepali", "value":  "ne"},
        {"key": "Norwegian", "value":  "no"},
        {"key": "Nyanja (Chichewa)", "value":  "ny"},
        {"key": "Odia (Oriya)", "value":  "or"},
        {"key": "Oromo", "value":  "om"},
        {"key": "Pashto", "value":  "ps"},
        {"key": "Persian", "value":  "fa"},
        {"key": "Polish", "value":  "pl"},
        {"key": "Portuguese (Portugal, Brazil)", "value":  "pt"},
        {"key": "Punjabi", "value":  "pa"},
        {"key": "Quechua", "value":  "qu"},
        {"key": "Romanian", "value":  "ro"},
        {"key": "Russian", "value":  "ru"},
        {"key": "Samoan", "value":  "sm"},
        {"key": "Sanskrit", "value":  "sa"},
        {"key": "Scots Gaelic", "value":  "gd"},
        {"key": "Sepedi", "value":  "nso"},
        {"key": "Serbian", "value":  "sr"},
        {"key": "Sesotho", "value":  "st"},
        {"key": "Shona", "value":  "sn"},
        {"key": "Sindhi", "value":  "sd"},
        {"key": "Sinhala (Sinhalese)", "value":  "si"},
        {"key": "Slovak", "value":  "sk"},
        {"key": "Slovenian", "value":  "sl"},
        {"key": "Somali", "value":  "so"},
        {"key": "Spanish", "value":  "es"},
        {"key": "Sundanese", "value":  "su"},
        {"key": "Swahili", "value":  "sw"},
        {"key": "Swedish", "value":  "sv"},
        {"key": "Tagalog (Filipino)", "value":  "tl"},
        {"key": "Tajik", "value":  "tg"},
        {"key": "Tamil", "value":  "ta"},
        {"key": "Tatar", "value":  "tt"},
        {"key": "Telugu", "value":  "te"},
        {"key": "Thai", "value":  "th"},
        {"key": "Tigrinya", "value":  "ti"},
        {"key": "Tsonga", "value":  "ts"},
        {"key": "Turkish", "value":  "tr"},
        {"key": "Turkmen", "value":  "tk"},
        {"key": "Twi (Akan)", "value":  "ak"},
        {"key": "Ukrainian", "value":  "uk"},
        {"key": "Urdu", "value":  "ur"},
        {"key": "Uyghur", "value":  "ug"},
        {"key": "Uzbek", "value":  "uz"},
        {"key": "Vietnamese", "value":  "vi"},
        {"key": "Welsh", "value":  "cy"},
        {"key": "Xhosa", "value":  "xh"},
        {"key": "Yiddish", "value":  "yi"},
        {"key": "Yoruba", "value":  "yo"},
        {"key": "Zulu", "value":  "zu"},
      ]

    }
  )

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Sends chat request to firestore/Dialog flow
  function chatHandler(e) {

    // Conditions
    if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e !== 'ManualClick') return;

    // Validate input
    if (formData.question.length === 0) {

      return setFormData({ 'questionError': 'Ummmm, are you going to ask me something?' });

    }

    // Reset input
    setFormData({
      'questionError': '',
    });

    // Write question to firestore
    const documentId = `${getUser.emailaddress}-${Date.now()}`;
    const document = {
      'id': documentId,
      'emailaddress': getUser.emailaddress,
      'question': formData.question,
      'languagecode': formData.targetLanguage.value,
      'status': 'pending', // 'pending', 'complete', 'complete-file', 'error-file'
    };

    writeDocument('translates', documentId, document, false).then(() => {

      setFormData({ 'question': '' });

    }).catch((error) => {

      setFormData({
        'question': '',
        'questionError': 'Unknown error occured while removing history, try again later.',
      });
      setAppErrors(`Failed to delete chat history for user, error: ${error}`);

    });

  };


  // Delete your previous
  function deleteChatHistory() {

    // Delete all previous chats
    const chatPromises = [];
    chats.forEach((document) => {

      if (document.id === undefined) return;

      chatPromises.push(
        deleteDocument('translates', document.id)
      );

    });

    Promise.all([...chatPromises]).catch((error) => {

      setFormData({
        'question': '',
        'questionError': 'Unknown error occured while removing history, try again later.'
      });
      setAppErrors(`Failed to delete chat history for user, error: ${error}`);

    });

  };

  // Save the name & lanaguge code to useReducer
  function handleLanguage(value){

    if (value === undefined) return;
    if (value.length === 0) return;

    setFormData({
      'targetLanguage': {
        'key': formData.langaugeCodes.filter((code) => code.value === value)[0].key,
        'value': value
      },
      'showOptions': false,
    });

    if (chats.length === 1) {
      setFormData({
        'questionError': `Translate text to '${formData.langaugeCodes.filter((code) => code.value === value)[0].key}'`
      });

    }

  };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Get all chats
  useEffect(() => {

    // Conditions
    if (getUser.emailaddress === undefined) return;

    function onLoadChange(documents) {

      if (documents.length === 0) {

        const welcomeMessage = [
          {
            'id': undefined,
            'emailaddress': getUser.emailaddress,
            'prompt': undefined,
            'answer': `Welcome to Google Cloud 'translate v2' model.
            \nAdd text through the below prompt. All generated content is secured to your account, and can be deleted at anytime by clicking the bin button.
            \nThanks for stopping by and enjoy :-)`,
            'status': 'complete',
          }
        ]

        setChats(welcomeMessage);
        setFormData({ 'questionError': `Translate text to '${formData.targetLanguage.key}'` });

      } else {

        // Reverse order of docs, so the thread has newest to the bottom.
        setChats(documents.reverse());

      }

      if (pageStatus !== 'onload') return setPageStatus('onload');

    }

    function onError(error) {

      setPageStatus('error-fatal');
      setAppErrors(`Failed to get documents from 'translates' collection, unable to create the document listener error: ${error}`);

    }

    const unsubscribe = queryListener('translates', [
      ['emailaddress', '==', getUser.emailaddress],
    ], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['isUser', 'isAdmin']}
      requiredRolesValue={true}
      status={pageStatus}

      //------------------------------------------------------
      //  Default chat screen
      //------------------------------------------------------

      body={
        <div className='Translate-Form'>

          {/* Thread */}
          <div className='Translate-Thread'>
            {
              chats?.map((chat, index) => (

                <div key={index}>

                  {/* ============================================= */}
                  {/*             Question asked by User            */}
                  {/* ============================================= */}

                  <div className='Translate-Thread-Question-Container' key={`${index}-2`}>
                    {

                      // Normal chats
                      chat.status === 'pending' || chat.status === 'complete' ? (

                        chat.question !== undefined && (
                          <span className='Translate-Thread-Question'>
                            {chat.question}
                          </span>
                        )

                      ) :

                      // Unknown status from the backend ~ Just hide the chat!
                      (
                        null
                      )

                    }

                  </div>

                  {/* ============================================= */}
                  {/*             Answer provided by AI             */}
                  {/* ============================================= */}

                  <div className='Translate-Thread-Answer-Container' key={`${index}-1`}>
                    {

                      // Pending status for a chat
                      chat.status === 'pending' ? (

                        <img className='Translate-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                        // Successful chat (text or file)
                      ) : chat.status === 'complete' || chat.status === 'complete-file' || chat.status === 'error-file' ? (

                        <span className='Translate-Thread-Answer'>
                          {chat.answer}
                        </span>

                      ) :

                      // 'pending-file' status shouldn't persist after the file is processed
                      chat.status === 'pending-file' ? (

                        <img className='Translate-Thread-Answer-Loading' alt='chat-loading' src={chatloadingIcon}></img>

                      ) :

                      // Unknown status from the backend ~ Just hide the chat!
                      (

                        null

                      )

                    }
                  </div>

                </div>
              ))
            }
          </div>

          {/* ============================================= */}
          {/*             Input Container                   */}
          {/* ============================================= */}

          {/* Input container on the bottom of the page */}
          <div className='Translate-Input-Container'>

            {/* Translate input box */}
            <input
              className='Translate-Search'
              placeholder={formData.questionError}
              id='question'
              value={formData.question}
              onChange={(e) => {
                setFormData({ 'question': e.target.value })
              }}
              onKeyDown={(e) => chatHandler(e)}
            ></input>

            {/* Search, upload file, delete, and settings thread icon */}
            <img
              className='Translate-Submit-Icon'
              alt='submit-icon'
              src={submitIcon}
              onClick={() => chatHandler('ManualClick')}
              title='Submit'
            ></img> 
            <IconAnimation
              className={'Translate-Delete-Icon'}
              tooltip={'Delete Translate History'}
              onClickHandler={() => deleteChatHistory()}
              iconDefault={deleteIcon}
              iconAnimation={deleteAnimation}
            ></IconAnimation>

            {/* Choose a Language Button & Label */}
            <img
              className='Translate-Settings-Icon'
              alt='language-icon'
              src={LanguageIcon}
              title='Select a language'
              onClick={() => setFormData({'showOptions': !formData.showOptions})}
            ></img>
            <label 
              title={`Current language '${formData?.targetLanguage?.key}'`} 
              style={{color: '#0C6CDE', fontWeight: '200', fontSize: '15px'}}
            >
              ({formData.targetLanguage.value})
            </label>

            {/* Choose a Language List */}
            <div hidden={!formData.showOptions} className='Translate-Settings-Options'>
              <label>Translate to:</label>
              <select onChange={(e) => handleLanguage(e.target.value)}>
                <option hidden defaultValue="Japanese">Japanese</option>
                {
                  formData.langaugeCodes.map((object, index) => (
                    <option key={index} value={object.value}>{object.key}</option>
                  ))
                }
              </select>
            </div>
          
          </div>

        </div>

      }
    ></PageComponent>
  )
}
